import React from "react";
import { observable, action, decorate } from "mobx";

/*
  --- USAGE:

  // Generic alert with OK button
  //
  this.props.rootStore.modalStore.showConfirm(
    'Alert',
    'This is a generic alert with default "OK" button and no callback'
  );

  // Generic confirm dialog with a custom button and click handler
  //
  this.props.rootStore.modalStore.showConfirm(
    'Alert',
    'This is a generic alert',
    'Okay!',
    () => alert('Callback called..')
  );

  // Generic choice popup with two custom buttons and click handlers
  //
  this.props.rootStore.modalStore.showChoice(
    'Survey',
    'Did you just see three alerts?',
    'No :-(',
    () => alert('Sorry about that.. There must be something wrong!'),
    'Yes :-)',
    () => alert('Great! Thanks for testing!')
  );

  // Custom dialog with our own components for content and buttons
  //
  const title = 'This is a custom dialog';

  const content = <div>
    <p>Please enter your name</p>
    <input type="text" onBlur={(evt) => console.log(evt.target.value)}></input>
  </div>

  const buttons = <React.Fragment><button className={'modal-btn modal-cancel-button'}
    onClick={() => {
      alert('You clicked the left button. Dialog will be closed.');
      this.closeModal();
    }}
  > {'Dismiss'}</button>
    <button className={'btn-ghost modal-confirm-button'}
      onClick={() => {
        alert('You clicked the right button. Dialog should stay open');
      }}
    > Do something</button></React.Fragment>;

  this.props.rootStore.modalStore.showModal(title, content, buttons);

*/

export default class ModalStore {
  modalVisible = false;
  title = "Modal title";
  content;
  buttons;
  modalClassName;

  secondaryTitle;
  secondaryContent;
  secondaryButtons;
  secondaryModalClassName;

  shouldCloseOnOverlayClick = false;
  modalQueue = [];

  helpVisible = false;
  helpTitle = "Info";
  helpText = "";
  onHelpLinkClicked;

  showModal(title, content, buttons, modalClassName, saveCurrentModal) {
    if (this.modalVisible) {
      if (saveCurrentModal) {
        this.secondaryTitle = title;
        this.secondaryContent = content;
        this.secondaryButtons = buttons;
        this.secondaryModalClassName = modalClassName;
      } else {
        this.queueModal(title, content, buttons, modalClassName);
      }
    } else {
      this.title = title;
      this.content = content;
      this.buttons = buttons;
      this.modalClassName = modalClassName;
      this.modalVisible = true;
    }
  }

  showConfirm(
    title,
    text,
    buttonText,
    buttonCallback,
    modalClassName,
    saveCurrentModal
  ) {
    const showTitle = title;
    const showContent = <div>{text}</div>;
    const showButtons = (
      <button
        className={"btn-ghost primary-button"}
        onClick={() => {
          this.hideModal();
          if (buttonCallback) {
            setTimeout(() => {
              buttonCallback();
            }, 1);
          }
        }}
      >
        {buttonText || "OK"}
      </button>
    );
    this.showModal(
      showTitle,
      showContent,
      showButtons,
      modalClassName,
      saveCurrentModal
    );
  }

  showChoice(
    title,
    text,
    ghostButtonText,
    ghostButtonCallback,
    defaultButtonText,
    defaultButtonCallback,
    modalClassName,
    saveCurrentModal
  ) {
    const showTitle = title;
    const showContent = text;
    const showButtons = (
      <React.Fragment>
        <button
          className={"modal-btn secondary-button"}
          onClick={() => {
            this.hideModal();
            if (ghostButtonCallback) {
              setTimeout(() => {
                ghostButtonCallback();
              }, 1);
            }
          }}
        >
          {" "}
          {ghostButtonText || "Abbrechen"}
        </button>
        <button
          className={"modal-btn primary-button"}
          onClick={() => {
            this.hideModal();
            if (defaultButtonCallback) {
              setTimeout(() => {
                defaultButtonCallback();
              }, 1);
            }
          }}
        >
          {" "}
          {defaultButtonText || "OK"}
        </button>
      </React.Fragment>
    );
    this.showModal(
      showTitle,
      showContent,
      showButtons,
      modalClassName,
      saveCurrentModal
    );
  }

  queueModal(title, content, buttons, modalClassName) {
    const entry = { title, content, buttons, modalClassName };
    this.modalQueue.push(entry);
  }

  showNextModal() {
    if (this.modalQueue.length > 0) {
      const entry = this.modalQueue.shift();
      this.showModal(
        entry.title,
        entry.content,
        entry.buttons,
        entry.modalClassName
      );
    }
  }

  hideModal() {
    if (this.secondaryContent) {
      this.secondaryTitle = undefined;
      this.secondaryContent = undefined;
      this.secondaryButtons = undefined;
      this.secondaryModalClassName = undefined;
    } else {
      this.modalVisible = false;
      this.title = "";
      this.content = undefined;
      this.buttons = undefined;
      this.modalClassName = undefined;
      setTimeout(() => {
        this.showNextModal();
      }, 100);
    }
  }

  showHelp(title, helpText, onHelpLinkClicked) {
    this.helpTitle = title;
    this.helpText = helpText;
    this.onHelpLinkClicked = onHelpLinkClicked;
    this.helpVisible = true;
  }

  hideHelp() {
    this.helpVisible = false;
    this.helpTitle = "";
    this.helpText = "";
    this.onHelpLinkClicked = undefined;
  }
}

decorate(ModalStore, {
  // Observables
  modalVisible: observable,
  secondaryContent: observable,
  helpVisible: observable,

  // Actions
  showModal: action,
  hideModal: action,
  showNextModal: action,
  showHelp: action,
  hideHelp: action,
});
