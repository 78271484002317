import React from "react";
import "./_style.scss";
import { generateGtmEventName } from "../../Utils/utils";

function TextInput(props) {
  const validationMessage = props.isInvalid && props.errorMsg;
  const className = "form-control" + (validationMessage ? " invalid" : "");
  return (
    <div className="text-input-container">
      <label htmlFor={props.label}>
        {props.label}{" "}
        {!props.isMandatory && <span className="optional-info">optional</span>}
      </label>
      <input
        className={className}
        type="text"
        name={props.label}
        placeholder={props.placeholder}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        onChange={(e) => {
          if (props.pattern) {
            const regexvalue = new RegExp(props.pattern);
            if (!regexvalue.test(e.target.value)) {
              props.onChange(e);
            } else {
              e.preventDefault();
            }
          } else {
            props.onChange(e);
          }
        }}
        onBlur={props.onBlur}
        disabled={props.disabled}
        value={props.value ? props.value : ""}
        pattern={props.pattern}
        data-gtmid={
          props.gtmInfo &&
          generateGtmEventName(
            props.gtmInfo.activeArea,
            props.gtmInfo.action,
            props.gtmInfo.description
          )
        }
      />
      {validationMessage && (
        <span className="text-error-message">{props.errorMsg}</span>
      )}
    </div>
  );
}

export default TextInput;
