import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { inject, observer } from "mobx-react";
import "./_style.scss";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import {
  COMPANY_NAME_LABEL,
  EMAIL_DOMAIN_LABEL,
  EMAIL_DOMAIN_PLACEHOLDER,
  QUESTION_BUSINESS_PARTNER_TAX,
  QUESTION_DEDUCTION_TAX,
  QUESTION_EMPLOYEE_TAX,
  FINANCIAL_ACCOUNTING_LABEL,
  PAYROLL_LABEL,
  EMPLOYER_COMPANY_HEADER,
  BUSINESS_PARTNER_TAX_HEADER,
  EMPLOYEE_TAX_HEADER,
  DEDUCT_TAX_HEADER,
  MAILBOX_HEADER,
  COMPANY_HEADER,
  MAILBOX_DETAILS_TEXT,
  ERROR_MESSAGE_COMPANY,
  ERROR_MESSAGE_ACCOUNTING,
  ERROR_MESSAGE_PAYROLL,
} from "./labels";

import {
  isInputFieldInValid,
  validateQuestionComponentField,
} from "./validationUtils";
import { getEmailDomain, scrollIntoViewById } from "../../../Utils/utils";
import { INVALID_EMAIL_DOMAIN_ERROR_MESSAGE } from "../../../Utils/constants";
import { helpTextSettings } from "../../../HelpText/settingsHelpText";
import { validateEmail } from "../../../sma-shared/Utils/utils";

function CompanyPage(props, ref) {
  const { apiStore, settingsStore, helpTextStore } = props.rootStore;
  const [isCompanyNameInvalid, setIsCompanyNameInvalid] = useState(false);
  const [isEmployeeTaxInvalid, setIsEmployeeTaxInvalid] = useState(false);
  const [isBusinessPartnerTaxInvalid, setIsBusinessPartnerTaxInvalid] =
    useState(false);
  const [isDeductionTaxInvalid, setIsDeductionTaxInvalid] = useState(false);
  const [isAccountingInvalid, setIsAccoutingInvalid] = useState(false);
  const [isPayrollInvalid, setIsPayrollInvalid] = useState(false);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    validate: validate,
  }));

  useEffect(() => {
    if (apiStore.settings !== null) {
      settingsStore.editSettings(apiStore.settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.settings]);

  const validate = () => {
    const companyNameInvalid = isInputFieldInValid(settingsStore.name, true);
    setIsCompanyNameInvalid(companyNameInvalid);
    const accountingInvalid = !checkCompanySettingsEmail(
      settingsStore.accounting
    );
    setIsAccoutingInvalid(accountingInvalid);
    const payrollInvalid = !checkCompanySettingsEmail(settingsStore.payroll);
    setIsPayrollInvalid(payrollInvalid);

    const employeeTaxInvalid = validateQuestionComponentField(
      settingsStore.isEmployeeTaxCovered
    );
    setIsEmployeeTaxInvalid(employeeTaxInvalid);
    const businessPartnerTaxInvalid = validateQuestionComponentField(
      settingsStore.isBusinessPartnerTaxCovered
    );
    setIsBusinessPartnerTaxInvalid(businessPartnerTaxInvalid);
    const deductionTaxInvalid = validateQuestionComponentField(
      settingsStore.isDeductionTax
    );
    setIsDeductionTaxInvalid(deductionTaxInvalid);

    const isValid =
      !companyNameInvalid &&
      !accountingInvalid &&
      !payrollInvalid &&
      !employeeTaxInvalid &&
      !businessPartnerTaxInvalid &&
      !deductionTaxInvalid;

    return isValid;
  };

  const checkCompanySettingsEmail = (email) => {
    const isEmailValid = validateEmail(email);
    if (isEmailValid) {
      const enteredEmailDomain = getEmailDomain(email);
      return settingsStore.domain.includes(enteredEmailDomain);
    }
    return isEmailValid;
  };

  const showHelpText = (helpTextId) => {
    helpTextStore.expandHelpText();
    scrollIntoViewById(helpTextId);
  };

  const companyInputs = () => [
    {
      type: INPUT_CARD_TYPE.text,
      label: COMPANY_NAME_LABEL,
      placeholder: " ",
      value: settingsStore.name,
      isInvalid: isCompanyNameInvalid,
      errorMsg: ERROR_MESSAGE_COMPANY,
      onChange: (e) => {
        settingsStore.name = e.target.value;
        setIsCompanyNameInvalid(isInputFieldInValid(settingsStore.name));
      },
    },
    {
      type: INPUT_CARD_TYPE.text,
      label: EMAIL_DOMAIN_LABEL,
      placeholder: EMAIL_DOMAIN_PLACEHOLDER,
      value: settingsStore.domain ? settingsStore.domain.join(", ") : "",
      disabled: true,
    },
  ];
  const employeeTaxInput = () => [
    {
      type: INPUT_CARD_TYPE.question,
      isMandatory: true,
      label: QUESTION_EMPLOYEE_TAX,
      id: "employeeTaxInput",
      isInvalid: isEmployeeTaxInvalid,
      itemChecked:
        settingsStore.isEmployeeTaxCovered === undefined
          ? undefined
          : settingsStore.isEmployeeTaxCovered
          ? "Ja"
          : "Nein",
      onClick: (e) => {
        settingsStore.isEmployeeTaxCovered = e === "Ja";
        setIsEmployeeTaxInvalid(
          validateQuestionComponentField(settingsStore.isEmployeeTaxCovered)
        );
      },
      onChange: (e) => {
        settingsStore.isEmployeeTaxCovered = e === "Ja";
        setIsEmployeeTaxInvalid(
          validateQuestionComponentField(settingsStore.isEmployeeTaxCovered)
        );
      },
    },
  ];

  const businessPartnerInput = () => [
    {
      type: INPUT_CARD_TYPE.question,
      isMandatory: true,
      label: QUESTION_BUSINESS_PARTNER_TAX,
      id: "businessPartnerInput",
      isInvalid: isBusinessPartnerTaxInvalid,
      itemChecked:
        settingsStore.isBusinessPartnerTaxCovered === undefined
          ? undefined
          : settingsStore.isBusinessPartnerTaxCovered
          ? "Ja"
          : "Nein",
      onClick: (e) => {
        settingsStore.isBusinessPartnerTaxCovered = e === "Ja";
        setIsBusinessPartnerTaxInvalid(
          validateQuestionComponentField(
            settingsStore.isBusinessPartnerTaxCovered
          )
        );
      },
      onChange: (e) => {
        settingsStore.isBusinessPartnerTaxCovered = e === "Ja";
        setIsBusinessPartnerTaxInvalid(
          validateQuestionComponentField(
            settingsStore.isBusinessPartnerTaxCovered
          )
        );
      },
    },
  ];
  const deductionTaxInput = () => [
    {
      type: INPUT_CARD_TYPE.question,
      isMandatory: true,
      label: QUESTION_DEDUCTION_TAX,
      id: "deductionTaxInput",
      isInvalid: isDeductionTaxInvalid,
      itemChecked:
        settingsStore.isDeductionTax === undefined
          ? undefined
          : settingsStore.isDeductionTax
          ? "Ja"
          : "Nein",
      onClick: (e) => {
        settingsStore.isDeductionTax = e === "Ja";
        setIsDeductionTaxInvalid(
          validateQuestionComponentField(settingsStore.isDeductionTax)
        );
      },
      onChange: (e) => {
        settingsStore.isDeductionTax = e === "Ja";
        setIsDeductionTaxInvalid(
          validateQuestionComponentField(settingsStore.isDeductionTax)
        );
      },
    },
  ];

  const mailBoxInputs = () => [
    {
      type: INPUT_CARD_TYPE.label,
      label: MAILBOX_DETAILS_TEXT,
    },

    {
      type: INPUT_CARD_TYPE.text,
      label: FINANCIAL_ACCOUNTING_LABEL,
      placeholder: " ",
      value: settingsStore.accounting.toString(),
      isInvalid: isAccountingInvalid,
      errorMsg: !validateEmail(settingsStore.accounting)
        ? ERROR_MESSAGE_ACCOUNTING
        : INVALID_EMAIL_DOMAIN_ERROR_MESSAGE,
      onChange: (e) => {
        settingsStore.accounting = e.target.value;
        setIsAccoutingInvalid(
          !checkCompanySettingsEmail(settingsStore.accounting)
        );
      },
      onBlur: (e) => {
        settingsStore.accounting = e.target.value.trim();
        setIsAccoutingInvalid(
          !checkCompanySettingsEmail(settingsStore.accounting)
        );
      },
    },
    {
      type: INPUT_CARD_TYPE.text,
      label: PAYROLL_LABEL,
      placeholder: " ",
      value: settingsStore.payroll.toString(),
      isInvalid: isPayrollInvalid,
      errorMsg: !validateEmail(settingsStore.payroll)
        ? ERROR_MESSAGE_PAYROLL
        : INVALID_EMAIL_DOMAIN_ERROR_MESSAGE,
      onChange: (e) => {
        settingsStore.payroll = e.target.value;
        setIsPayrollInvalid(!checkCompanySettingsEmail(settingsStore.payroll));
      },
      onBlur: (e) => {
        settingsStore.payroll = e.target.value.trim();
        setIsPayrollInvalid(!checkCompanySettingsEmail(settingsStore.payroll));
      },
    },
  ];

  return (
    <div className="flex-container">
      <div className="create-gift-page-content">
        <div className="page-header">
          <span className="title">{COMPANY_HEADER}</span>
        </div>
        <InputCard
          title={EMPLOYER_COMPANY_HEADER}
          helpTextId={
            helpTextSettings && helpTextSettings.employerCompany
              ? helpTextSettings.employerCompany.id
              : ""
          }
          onClickHelpText={showHelpText}
          isMandatory
          data={companyInputs()}
        />
        <InputCard
          title={EMPLOYEE_TAX_HEADER}
          helpTextId={
            helpTextSettings && helpTextSettings.employeeTax
              ? helpTextSettings.employeeTax.id
              : ""
          }
          onClickHelpText={showHelpText}
          isMandatory
          data={employeeTaxInput()}
        />
        <InputCard
          title={BUSINESS_PARTNER_TAX_HEADER}
          helpTextId={
            helpTextSettings && helpTextSettings.businessPartnerTax
              ? helpTextSettings.businessPartnerTax.id
              : ""
          }
          onClickHelpText={showHelpText}
          isMandatory
          data={businessPartnerInput()}
        />
        <InputCard
          title={DEDUCT_TAX_HEADER}
          helpTextId={
            helpTextSettings && helpTextSettings.deductTax
              ? helpTextSettings.deductTax.id
              : ""
          }
          onClickHelpText={showHelpText}
          isMandatory
          data={deductionTaxInput()}
        />
        <InputCard
          title={MAILBOX_HEADER}
          helpTextId={
            helpTextSettings && helpTextSettings.mailbox
              ? helpTextSettings.mailbox.id
              : ""
          }
          onClickHelpText={showHelpText}
          isMandatory
          data={mailBoxInputs()}
        />
      </div>
      <div className="grey-container-without-sidebar"></div>
    </div>
  );
}

export default inject("rootStore")(observer(forwardRef(CompanyPage)));
