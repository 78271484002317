import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { ROLES } from "../../../Utils/constants";
import { scrollIntoViewById } from "../../../Utils/utils";
import "./_style.scss";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import { PERSONAL_DATA_TEXT, ROLE_DESCRIPTION, ROLE_TEXT } from "../labels";
import { helpTextEditEmployee } from "../../../HelpText/editEmployeeHelpText";

function EditEmployee(props) {
  const { apiStore, employeeStore, helpTextStore } = props.rootStore;
  const { employeeId } = useParams();
  const [isAccounting, setIsAccounting] = useState(false);
  const [isPayroll, setIsPayroll] = useState(false);

  useEffect(() => {
    helpTextStore.setHelpText(helpTextEditEmployee);
    return () => {
      helpTextStore.resetHelpText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStore.employees]);

  useEffect(() => {
    setIsAccounting(employeeStore.roles.includes(ROLES.accounting));
    setIsPayroll(employeeStore.roles.includes(ROLES.payroll));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeStore.roles]);

  function loadEmployeeData() {
    if (typeof employeeId !== "undefined") {
      const employee = props.rootStore.apiStore.employees.find(
        (item) => item.id === employeeId
      );
      if (employee) {
        employeeStore.editEmployee(employee);
      }
    }
  }

  const updateRole = (roleName, value) => {
    const roles = employeeStore.roles.filter((item) => item !== roleName);
    if (value) {
      roles.push(roleName);
    }
    employeeStore.roles = roles;
  };

  const personalDataInputs = () => [
    {
      type: INPUT_CARD_TYPE.text,
      label: "Nachname",
      disabled: true,
      placeholder: " ",
      value: employeeStore.lastName,
    },
    {
      type: INPUT_CARD_TYPE.text,
      label: "Vorname",
      disabled: true,
      placeholder: " ",
      value: employeeStore.firstName,
    },
    {
      type: INPUT_CARD_TYPE.text,
      label: "E-Mail-Adresse",
      disabled: true,
      placeholder: " ",
      value: employeeStore.email,
    },
    {
      type: INPUT_CARD_TYPE.text,
      label: "Personalnummer",
      placeholder: " ",
      isMandatory: false,
      value: employeeStore.personalNumber,
      onChange: (e) => {
        employeeStore.personalNumber = e.target.value;
      },
    },
  ];

  const roleInputs = () => [
    {
      type: INPUT_CARD_TYPE.label,
      label: ROLE_DESCRIPTION,
    },
    {
      id: "accounting",
      type: INPUT_CARD_TYPE.checkbox,
      name: "Mitarbeiter der Fibu",
      checked: isAccounting,
      onChange: () => {
        updateRole(ROLES.accounting, !isAccounting);
        setIsAccounting(!isAccounting);
      },
    },
    {
      id: "payroll",
      type: INPUT_CARD_TYPE.checkbox,
      name: "Mitarbeiter der Entgeltabrechnung",
      checked: isPayroll,
      onChange: () => {
        updateRole(ROLES.payroll, !isPayroll);
        setIsPayroll(!isPayroll);
      },
    },
  ];

  const showHelpText = (helpTextId) => {
    helpTextStore.expandHelpText();
    scrollIntoViewById(helpTextId);
  };

  return (
    <div className="flex-container">
      <div className="create-gift-page-content">
        <div className="page-header">
          <span className="title">Mitarbeiter</span>
        </div>
        <InputCard
          title={PERSONAL_DATA_TEXT}
          isMandatory
          data={personalDataInputs()}
          helpTextId={helpTextEditEmployee.personalData}
          onClickHelpText={showHelpText}
        />
        <InputCard
          title={ROLE_TEXT}
          isMandatory
          data={roleInputs()}
          helpTextId={helpTextEditEmployee.role}
          onClickHelpText={showHelpText}
        />
      </div>
      <div
        className="grey-container-without-sidebar"
        id="grey-container-edit-employee"
      ></div>
    </div>
  );
}

export default inject("rootStore")(observer(EditEmployee));
