import React, { useState, useEffect } from "react";
import { EditCellType } from "../../../Utils/constants";
import {
  roundDecimalsToDivision,
  validateDecimalAsText,
  validateIntAsText,
  roundToDecimals,
} from "../../../sma-shared/Utils/utils";

const formatDisplayValue = (cellProps) => {
  const { value, type, decimalDigits, roundToDivision } = cellProps;

  if (value === undefined || value === "") {
    return "";
  }

  switch (type) {
    case EditCellType.number:
    case EditCellType.percentage:
      const number = roundToDivision
        ? roundDecimalsToDivision(value, roundToDivision)
        : roundToDecimals(value, decimalDigits);
      return number.toString().replace(/\./g, ",");
    case EditCellType.text:
    default:
      return value;
  }
};

const validateValue = (cellProps) => {
  // empty string, null, undefine it is an valid imput also as a number (empty value)
  const { value, type, decimalDigits, minValue, maxValue, pattern } = cellProps;
  if (!value) {
    return true;
  }

  switch (type) {
    case EditCellType.number:
    case EditCellType.percentage:
      let validateNumber = !decimalDigits
        ? validateIntAsText(value)
        : validateDecimalAsText(value);
      if (validateNumber && decimalDigits > 0) {
        validateNumber &=
          value.indexOf(",") === -1 ||
          value.length - value.indexOf(",") <= decimalDigits + 1; // not allow more decimal digits
        validateNumber &= value.indexOf(".") === -1; // allow only ',' as decimal digits
        validateNumber &= !(
          value.length >= 2 &&
          value[0] === "0" &&
          value[1] !== ","
        ); // if first digit is '0' next one can be only ','
      }
      if (validateNumber) {
        const numberValue = roundToDecimals(value, decimalDigits);
        if (minValue && numberValue < roundToDecimals(minValue, decimalDigits))
          return false; // if minValue is define and value is smaller than it
        if (maxValue && numberValue > roundToDecimals(maxValue, decimalDigits))
          return false; // if maxValue is define and value is greater than it
        return true;
      } else {
        return false;
      }
    case EditCellType.text:
    default:
      if (pattern) {
        const patternCheck = new RegExp(pattern);
        if (patternCheck.test(value)) {
          return false;
        }
      }

      return true;
  }
};

function EditableCell({
  cell: { value: initialValue },
  row: { index },
  column: {
    id,
    placeholder,
    type,
    decimalDigits,
    roundToDivision,
    minValue,
    maxValue,
    pattern,
    disabled,
  },
  errors,
  update,
}) {
  const cellProps = {
    type,
    decimalDigits,
    roundToDivision,
    minValue,
    maxValue,
    pattern,
  };
  cellProps.value = initialValue;
  const [value, setValue] = useState(formatDisplayValue(cellProps));

  const hasError = () => {
    if (errors) {
      const cellWithError = errors.find(
        (e) => e.row === index && e.column === id
      );
      return cellWithError !== undefined;
    }
    return false;
  };

  const onChange = (e) => {
    cellProps.value = e.target.value;
    if (validateValue(cellProps)) {
      setValue(e.target.value);
    } else {
      setValue(value);
    }
  };

  const onBlur = () => {
    cellProps.value = value;
    const valueFormated = formatDisplayValue(cellProps);
    const valueToSave =
      valueFormated &&
      (type === EditCellType.number || type === EditCellType.percentage)
        ? roundToDecimals(valueFormated)
        : valueFormated;
    setValue(valueFormated);
    update(index, id, valueToSave);
  };

  useEffect(() => {
    cellProps.value = initialValue;
    const formatedValue = formatDisplayValue(cellProps);
    setValue(formatedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <input
      value={value}
      className={`${hasError() ? "error" : ""}`}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}

export default EditableCell;
