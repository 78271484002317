import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { inject, observer } from "mobx-react";
import "./_style.scss";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import {
  COMPLIANCE_HEADER,
  GIFT_GUIDELINE_HEADER,
  GIFT_GUIDELINE_INFO,
  COMPLIANCE_URL_INVALID_ERROR_MESSAGE,
} from "./labels";
import { isEmpty, validateUrl } from "../../../sma-shared/Utils/utils";
import {
  UPLOAD_FILE_ERROR_MESSAGE,
  MAX_FILES_TO_UPLOAD,
  activeArea,
  actions,
} from "../../../Utils/constants";

function CompliancePage(props, ref) {
  const { apiStore, settingsStore } = props.rootStore;
  const [fileAttachments, setFileAttachments] = useState([]);
  const [isValidUrl, setIsValidUrl] = useState(true);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    validate: isValidUrl,
  }));

  useEffect(() => {
    if (settingsStore.fileAttachments !== null) {
      setFileAttachments(settingsStore.fileAttachments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsStore.fileAttachments]);

  const createAttachmentInputs = () => {
    return [
      {
        type: INPUT_CARD_TYPE.label,
        label: GIFT_GUIDELINE_INFO,
      },
      {
        type: INPUT_CARD_TYPE.text,
        label: "URL",
        placeholder: " ",
        value: settingsStore.url,
        isInvalid: !isValidUrl,
        errorMsg: COMPLIANCE_URL_INVALID_ERROR_MESSAGE,
        gtmInfo: {
          activeArea: activeArea.settings,
          action: actions.insert,
          description: "URLCompliance",
        },
        onChange: (e) => {
          // check that url startswith http or https, otherwise it might not route correctly
          if (isEmpty(e.target.value)) {
            setIsValidUrl(true);
            settingsStore.url = "";
          } else {
            setIsValidUrl(validateUrl(e.target.value));
            settingsStore.url = e.target.value;
          }
        },
        onBlur: (e) => {
          if (isEmpty(e.target.value)) {
            setIsValidUrl(true);
            settingsStore.url = "";
          } else {
            setIsValidUrl(validateUrl(e.target.value));
            settingsStore.url = e.target.value;
          }
        },
      },
      {
        step: "CompliancePage",
        type: INPUT_CARD_TYPE.fileUpload,
        label: "Dateien hinzufügen",
        multiple: false,
        fileAttachments: fileAttachments,
        notAllowToUpload: settingsStore.idFromDB === "",
        notAllowToUploadMessage: UPLOAD_FILE_ERROR_MESSAGE,
        rootStore: props.rootStore,
        disabled: fileAttachments.length >= MAX_FILES_TO_UPLOAD,
        uploadFile: async (file) => apiStore.uploadComplianceAttachment(file),
        deleteFile: async (fileId) =>
          apiStore.deleteComplianceAttachment(fileId),
        downloadFile: async (fileId) =>
          apiStore.downloadComplianceAttachment(fileId),
      },
    ];
  };
  return (
    <div className="basic-data-container flex-container">
      <div className="create-gift-page-content">
        <div className="page-header">
          <span className="title">{COMPLIANCE_HEADER}</span>
        </div>
        <InputCard
          title={GIFT_GUIDELINE_HEADER}
          data={createAttachmentInputs()}
        />
      </div>
      <div className="grey-container-without-sidebar"></div>
    </div>
  );
}

export default inject("rootStore")(observer(forwardRef(CompliancePage)));
