import "react-app-polyfill/ie11";
/*  Needed for IE11 suppoer */
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import "./sma-shared/Styles/index.scss";
import RootStore from "./Stores/RootStore";
import App from "./Pages/App";
import * as serviceWorker from "./serviceWorker";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "!file-loader?name=[name].[ext]!./Assets/pdf/Geschenke-Manager_AVV_20211206.pdf";

export const rootStore = new RootStore();

const app = (
  <Provider rootStore={rootStore}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

/* Added hot-reloading without browser refresh */
if (module.hot)
  module.hot.accept("./Pages/App", () => {
    const NextRootContainer = require("./Pages/App").default;
    ReactDOM.render(<NextRootContainer />, document.getElementById("root"));
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
