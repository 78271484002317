import React from "react";
import { SETTINGS_PATH } from "../../Utils/paths";
import { Link } from "react-router-dom";
import {
  chevronRightIcon,
  alertIcon,
} from "../../Assets/dynamic-svg/general-icons";
import { blackPearl } from "../../Utils/styleHelper";
import "./_style.scss";

function NotificationArea({ message }) {
  return (
    <div className="notification-area">
      <div className="content-area">
        <div className="icon-area">{alertIcon(blackPearl)}</div>
        <div className="message-area">{message}</div>
      </div>
      <div className="link-area">
        <Link to={SETTINGS_PATH}>
          Einstellungen aufrufen {chevronRightIcon(blackPearl)}
        </Link>
      </div>
    </div>
  );
}

export default NotificationArea;
