import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import CollapsibleCard from "../../../Components/Collapsible";
import SideBarRow from "../../../Components/SideBarRow";
import "./_style.scss";
import { listIcon } from "../../../Assets/dynamic-svg/general-icons";
import {
  status,
  GIFT_VALUE_TYPE,
  receiverType,
  reasonOptions,
} from "../../../Utils/constants";
import ReceiversTable from "./receiversTable";
import { formatNumber } from "../../../sma-shared/Utils/utils";
import FileDataList from "../../../Components/FileDataList";
import {
  COMPANY_TEXT,
  DESCRIPTION_GIFT_TEXT,
  DESCRIPTION_OCCASION_TEXT,
  EMAIL_TEXT,
  SENT_BY_EMAIL,
  FIRST_NAME_TEXT,
  GIFTDATE_TEXT,
  GIFTVALUE_TEXT,
  INVOICE_NUMBER_TEXT,
  LAST_NAME_TEXT,
  INTERNAL_INVOICE_NUMBER_TEXT,
  APPROVER_TITLE_CARD,
  GIFT_TITLE_CARD,
  RECEIVER_TITLE_CARD,
  DOCUMENT_TEXT,
  LIST_OF_RECEIVERS_TEXT,
  FALLDATEN_TITLE_PAGE,
  FALLDATEN_DESCRIPTION,
  REASON_TEXT_SIMPLE,
  NET_VALUE_TEXT,
  GROSS_VALUE_TEXT,
  APPROVED_BY,
  PERSONAL_ID,
} from "../giftCaseLabels";
import moment from "moment";

function SideBar(props) {
  const { giftDataStore, modalStore, createGiftStore, apiStore } =
    props.rootStore;
  const [isOpenReceiverRow, setIsOpenReceiverRow] = useState(true);
  const [isOpenGiftRow, setIsOpenGiftRow] = useState(true);
  const [isOpenSenderApproverRow, setIsOpenSenderApproverRow] = useState(true);

  const showReceiverList = () => {
    modalStore.showConfirm(
      RECEIVER_TITLE_CARD,
      <ReceiversTable
        listOfReceivers={giftDataStore.receivers}
        receiversType={giftDataStore.receiverType}
      />,
      "Schließen"
    );
  };

  const getReceiverRows = () => {
    if (giftDataStore.receivers.length === 1) {
      return (
        <div className="sidebar-container">
          <SideBarRow
            propertyName={LAST_NAME_TEXT}
            value={
              giftDataStore.receivers[0] && giftDataStore.receivers[0].lastName
                ? giftDataStore.receivers[0].lastName
                : ""
            }
          ></SideBarRow>
          <SideBarRow
            propertyName={FIRST_NAME_TEXT}
            value={
              giftDataStore.receivers[0] && giftDataStore.receivers[0].firstName
                ? giftDataStore.receivers[0].firstName
                : ""
            }
          ></SideBarRow>

          {giftDataStore.receiverType === receiverType.bussinesPartner.value ? (
            <SideBarRow
              propertyName={COMPANY_TEXT}
              value={
                giftDataStore.receivers[0] && giftDataStore.receivers[0].company
                  ? giftDataStore.receivers[0].company
                  : ""
              }
            ></SideBarRow>
          ) : (
            ""
          )}
          {giftDataStore.receiverType === receiverType.employee.value ? (
            <SideBarRow
              propertyName={PERSONAL_ID}
              value={
                giftDataStore.receivers[0] &&
                giftDataStore.receivers[0].personalId
                  ? giftDataStore.receivers[0].personalId
                  : ""
              }
            ></SideBarRow>
          ) : (
            ""
          )}
          <SideBarRow
            propertyName={EMAIL_TEXT}
            value={
              giftDataStore.receivers[0] && giftDataStore.receivers[0].email
                ? giftDataStore.receivers[0].email
                : ""
            }
          ></SideBarRow>
          <div className="side-bar-row-line" />
        </div>
      );
    }
    return (
      <div className="sidebar-container">
        <SideBarRow
          propertyName={"Anzahl"}
          value={giftDataStore.receivers.length}
        ></SideBarRow>
        <SideBarRow
          propertyName={
            <span
              className="icon-sidebar"
              id="receiver-sidebar"
              onClick={showReceiverList}
            >
              {listIcon("#2072E6")} {LIST_OF_RECEIVERS_TEXT}
            </span>
          }
          value={""}
        ></SideBarRow>
        <div className="side-bar-row-line" />
      </div>
    );
  };

  const getGiftDetailsRows = () => {
    return (
      <div className="sidebar-container">
        <div>
          <SideBarRow
            propertyName={REASON_TEXT_SIMPLE}
            value={
              giftDataStore.giftReason &&
              giftDataStore.giftReason in reasonOptions
                ? reasonOptions[giftDataStore.giftReason].label
                : ""
            }
          ></SideBarRow>
          <SideBarRow
            propertyName={DESCRIPTION_OCCASION_TEXT}
            value={
              giftDataStore.giftDescriptionOccasion
                ? giftDataStore.giftDescriptionOccasion
                : ""
            }
          ></SideBarRow>
          <SideBarRow
            propertyName={DESCRIPTION_GIFT_TEXT}
            value={
              giftDataStore.giftDescription ? giftDataStore.giftDescription : ""
            }
          ></SideBarRow>
        </div>
        <SideBarRow
          propertyName={GIFTDATE_TEXT}
          value={
            giftDataStore.giftDate
              ? moment(giftDataStore.giftDate).format("DD.MM.YYYY")
              : ""
          }
        ></SideBarRow>
        <SideBarRow
          propertyName={`${GIFTVALUE_TEXT(
            GIFT_VALUE_TYPE[giftDataStore.giftValueType]
              ? GIFT_VALUE_TYPE[giftDataStore.giftValueType].label
              : ""
          )}`}
          value={
            giftDataStore.giftValue
              ? formatNumber(giftDataStore.giftValue) + " EUR"
              : ""
          }
        ></SideBarRow>

        <SideBarRow
          propertyName={GROSS_VALUE_TEXT}
          value={
            giftDataStore.grossValue
              ? formatNumber(giftDataStore.grossValue) + " EUR"
              : ""
          }
        ></SideBarRow>
        <SideBarRow
          propertyName={NET_VALUE_TEXT}
          value={
            giftDataStore.netValue
              ? formatNumber(giftDataStore.netValue) + " EUR"
              : ""
          }
        ></SideBarRow>
        <React.Fragment>
          <SideBarRow
            propertyName={INTERNAL_INVOICE_NUMBER_TEXT}
            value={
              giftDataStore.internalNumber ? giftDataStore.internalNumber : ""
            }
          ></SideBarRow>
          <SideBarRow
            propertyName={INVOICE_NUMBER_TEXT}
            value={
              giftDataStore.invoiceNumber ? giftDataStore.invoiceNumber : ""
            }
          ></SideBarRow>
          <SideBarRow
            propertyName={DOCUMENT_TEXT}
            value={getInvoiceAttachComponent()}
          ></SideBarRow>
        </React.Fragment>
        <div className="side-bar-row-line" />
      </div>
    );
  };

  const getInvoiceAttachComponent = () => {
    return (
      <FileDataList
        attachments={giftDataStore.invoiceAttachments}
        idFromDB={giftDataStore.idFromDB}
        rootStore={props.rootStore}
        onDownload={async (fileId) =>
          apiStore.downloadInvoiceAttachment(giftDataStore.idFromDB, fileId)
        }
      />
    );
  };

  const getSenderApproverDetails = () => {
    return (
      <div className="side-bar-container">
        <SideBarRow
          propertyName={SENT_BY_EMAIL}
          value={giftDataStore.senderEmail ? giftDataStore.senderEmail : ""}
        ></SideBarRow>
        <SideBarRow
          propertyName={APPROVED_BY}
          value={
            giftDataStore.getStatus(status.approved)
              ? giftDataStore.getStatus(status.approved).email
              : ""
          }
        ></SideBarRow>
        <div className="side-bar-row-line" />
      </div>
    );
  };

  const collapsibleReceiverRowsClicked = () => {
    setIsOpenReceiverRow(!isOpenReceiverRow);
    createGiftStore.rightContainerSizeChanged =
      !createGiftStore.rightContainerSizeChanged;
  };

  const collapsibleGiftRowsClicked = () => {
    setIsOpenGiftRow(!isOpenGiftRow);
    createGiftStore.rightContainerSizeChanged =
      !createGiftStore.rightContainerSizeChanged;
  };

  const collapsibleSenderApproverRowsClicked = () => {
    setIsOpenSenderApproverRow(!isOpenSenderApproverRow);
    createGiftStore.rightContainerSizeChanged =
      !createGiftStore.rightContainerSizeChanged;
  };

  return (
    <div className="side-bar-right">
      <div>
        <div className="title">{FALLDATEN_TITLE_PAGE}</div>
        <div className="description">{FALLDATEN_DESCRIPTION}</div>
      </div>
      <div>
        <div className="collapsible-container">
          {props.showReceiver && (
            <CollapsibleCard
              isOpen={isOpenReceiverRow}
              onCollapsibleCardClicked={collapsibleReceiverRowsClicked}
              title={RECEIVER_TITLE_CARD}
              children={getReceiverRows()}
            />
          )}
          {props.showGift && (
            <CollapsibleCard
              isOpen={isOpenGiftRow}
              onCollapsibleCardClicked={collapsibleGiftRowsClicked}
              title={GIFT_TITLE_CARD}
              children={getGiftDetailsRows()}
            />
          )}
          {props.showApproval && props.showSender && (
            <CollapsibleCard
              isOpen={isOpenSenderApproverRow}
              onCollapsibleCardClicked={collapsibleSenderApproverRowsClicked}
              title={APPROVER_TITLE_CARD}
              children={getSenderApproverDetails(props.giftStatus)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default inject("rootStore")(observer(SideBar));
