import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import "./_style.scss";
import Filters from "../../../Components/Filters";
import { rolesStatus } from "../../../Utils/constants";
import { ALL_FILTER_VALUE } from "../../../Stores/FiltersStore";

function LeftContent(props) {
  const { apiStore, filtersStore } = props.rootStore;
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    const filters = [];
    for (const roles of Object.values(rolesStatus)) {
      filterOptions.push({ label: roles.label, value: roles.value });
    }
    setFilterOptions(filters);
    filtersStore.setFilterOptions(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNumberOfEntriesForFilter = (filterValue) => {
    const { apiStore } = props.rootStore;
    const entries = apiStore.employees;

    const nrFilteredEntries = entries.filter(
      (entry) => entry.roles !== undefined && entry.roles.includes(filterValue)
    ).length;

    return filterValue === ALL_FILTER_VALUE
      ? entries.length
      : nrFilteredEntries;
  };
  const renderFilters = () => {
    return apiStore.settings ? (
      <Filters
        searchTitle="Suche"
        searchPlaceholder="z. B. Name"
        filterTitle="ROLLE"
        getNumberOfEntriesForFilter={getNumberOfEntriesForFilter}
      />
    ) : (
      ""
    );
  };

  return <div className="container-left">{renderFilters()}</div>;
}

export default inject("rootStore")(observer(LeftContent));
