import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./_style.scss";

/* separator used for the footer links */
const separator = <span> | </span>;

function FooterLinks(props) {
  return (
    <div className="footer-links-left">
      {props.footerLinks.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`footer-link ${index}`}>
          {index > 1 && separator} {item}
        </Fragment>
      ))}
    </div>
  );
}

FooterLinks.propTypes = {
  footerLinks: PropTypes.array,
};

export default FooterLinks;
