import { decorate, observable, action } from "mobx";
import { isSameStringArray } from "../Utils/utils";
import { UPDATE_STATUS } from "../Utils/constants";
import { overwriteChangesModal } from "../Utils/overwriteDialog";

export default class EmployeeStore {
  apiStore;

  // user
  firstName = "";
  lastName = "";
  email = "";
  personalNumber = "";
  roles = [];
  refreshedAt = "";

  // db
  dbEmployee;

  constructor(rootStore) {
    this.apiStore = rootStore.apiStore;
    this.modalStore = rootStore.modalStore;
  }

  reset() {
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.personalNumber = "";
    this.roles = [];
    this.refreshedAt = "";
  }

  editEmployee(employeeObject) {
    this.reset();
    if (!employeeObject) {
      return;
    }

    this.id = employeeObject.id;
    this.firstName = employeeObject.firstName;
    this.lastName = employeeObject.lastName;
    this.email = employeeObject.email;
    this.personalNumber = employeeObject.personalNumber;
    this.roles = employeeObject.roles;
    this.refreshedAt = employeeObject.refreshedAt;

    this.dbEmployee = employeeObject;
  }

  async updateEmployee() {
    const data = await this.apiStore.updateEmployee(
      this.getEmployeeObjectForUpdate()
    );
    if (data.message === UPDATE_STATUS.success) {
      this.editEmployee(data.employee);
    } else {
      overwriteChangesModal(
        this.modalStore,
        data.message === UPDATE_STATUS.changedByOther,
        () => {
          this.editEmployee(data.employee);
        },
        () => {
          const data = this.apiStore.updateEmployee(
            this.getEmployeeObjectForUpdate(),
            true
          );
          if (data.message === UPDATE_STATUS.success) {
            this.editEmployee(data.employee);
          }
        }
      );
    }
  }

  isEmployeeUpdated() {
    return (
      this.personalNumber !== this.dbEmployee.personalNumber ||
      !isSameStringArray(this.roles, this.dbEmployee.roles)
    );
  }

  getEmployeeObjectForUpdate() {
    //update user api just update personalNumber and roles
    return {
      id: this.id,
      personalNumber: this.personalNumber,
      roles: this.roles,
      refreshedAt: this.refreshedAt,
    };
  }
}

decorate(EmployeeStore, {
  // Observables
  firstName: observable,
  lastName: observable,
  email: observable,
  personalNumber: observable,
  roles: observable,

  // Actions
  editEmployee: action,
  updateEmployee: action,
  isEmployeeUpdated: action,
});
