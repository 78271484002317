export const STATUS_LABEL = {
  draft: { label: "Abgesendet durch:", text: "Abgesendet" },
  approved: { label: "Genehmigung durch:", text: "Genehmigt" },
  rejected: { label: "Abgelehnt durch:", text: "Abgelehnt" },
  payrollProcessing: { label: "Bearbeitung durch Fibu:", text: "Bearbeitet" },
  completed: {
    label: "Bearbeitung durch Entgeltabrechnung:",
    text: "Bearbeitet",
  },
};
