import React from "react";
import Dropdown from "./DropdownComponent";

function DropdownInput(props) {
  return (
    <div className="text-input-container">
      <label htmlFor={props.label}>
        {props.label}
        {!props.isMandatory && <span className="optional-info">optional</span>}
      </label>
      <Dropdown
        className={props.className}
        height={props.height}
        dropdownOptions={props.options}
        isSearchable={props.isSearchable}
        isInvalid={props.isInvalid}
        errorMsg={props.errorMsg}
        placeholder={props.placeholder}
        selectedDropdownValue={props.value}
        getSelectedDropdownValue={(value) => props.onClick(value)}
        menuPlacement={props.menuPlacement}
        isDisabled={props.disabled}
      />
    </div>
  );
}

export default DropdownInput;
