import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { crossIcon } from "../../Assets/dynamic-svg/general-icons";
import { royalBlue } from "../../Utils/styleHelper";
import "./_style.scss";

function FiltersBar(props) {
  const { filtersStore } = props.rootStore;
  const [activeFilters, setActiveFilters] = useState(
    filtersStore.activeFilters
  );
  const [showFilterRow, setShowFilterRow] = useState(
    filtersStore.activeFilters.length > 0
  );

  useEffect(() => {
    setActiveFilters(filtersStore.activeFilters);
    setShowFilterRow(filtersStore.activeFilters.length > 0);
  }, [filtersStore.activeFilters]);

  const renderActiveFilters = () => {
    return activeFilters.map((filter, index) => (
      <div key={`active-filter-${index}`} className="active-filter">
        <span className="active-filter-title">{filter.label}</span>
        <button
          onClick={() => {
            filtersStore.resetActiveFilters();
          }}
        >
          <span className="icon-general">{crossIcon(royalBlue)}</span>
          <span>Filter zurücksetzen</span>
        </button>
      </div>
    ));
  };

  const filterRow = () => {
    return (
      <section className="active-filters-container">
        {renderActiveFilters()}
      </section>
    );
  };

  return <section>{showFilterRow && filterRow()}</section>;
}

export default inject("rootStore")(observer(FiltersBar));
