import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Datepicker from "react-datepicker";
import calendarIcon from "../../Assets/svg/calendar-icon.svg";
import calendarLockedIcon from "../../Assets/svg/calendar-icon-locked.svg";
import InfoButton from "../../Components/InfoButton";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/de";
import de from "date-fns/locale/de";
import shallowCompare from "react-addons-shallow-compare";
class DatepickerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarObj: undefined,
      isOpen: false,
      inputValue: this.formatDate(this.props.inputValue),
      invalidDateFormat: false,
      invalidDateRange: false,
    };
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  onInputChange(event) {
    this.setState({
      inputValue: event.target.value,
    });
  }

  onInputBlur() {
    this.validateInput(this.state.inputValue);
  }

  /* Calendar object is needed to open/close the calendar from the datepicker */
  setCalendarObj(calendar) {
    this.setState({
      calendarObj: calendar,
    });
  }

  formatDate(date) {
    if (date) {
      if (isNaN(date)) {
        if (date.split(".").length === 3 && date.split(".")[2] !== "") {
          const day = date.split(".")[0];
          const month = date.split(".")[1];
          let year = date.split(".")[2];
          date = new Date(year, month - 1, day);
        } else {
          return "";
        }
      }
      date = Date.parse(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      try {
        return new Intl.DateTimeFormat("de-DE", options).format(date);
      } catch (e) {
        return "";
      }
    } else {
      return "";
    }
  }

  toggle(calendar) {
    calendar.setOpen(this.focus);
    this.focus = !this.focus;
  }

  validateInput(input) {
    /* by default date should be valid */
    this.setState({
      invalidDateInput: false,
      invalidDateRange: false,
      invalidDateFormat: false,
    });

    /* if no date -> don't do anything */
    if (input === null || input === "") {
      this.updateState(null);
      this.updateParentComponent(null);
      return;
    }

    /* try to format the inputed date */
    let formattedInput = moment(input, "DD.MM.YYYY");

    if (formattedInput._isValid === true) {
      /* check date ranges */
      if (
        (this.props.minDate &&
          moment(formattedInput).isBefore(this.props.minDate, "days")) ||
        (this.props.maxDate &&
          moment(formattedInput).isAfter(this.props.maxDate, "days"))
      ) {
        this.setState({
          invalidDateRange: true,
        });
        this.updateParentComponent(null);
        return;
      }
    }

    /* formatting failed */
    if (formattedInput._isValid !== true) {
      this.setState({
        invalidDateFormat: true,
      });
      this.updateParentComponent(null);
      return;
    }

    /* formatted date */
    this.updateState(input);
    this.updateParentComponent(input);
  }

  updateState(date) {
    if (!date) {
      this.setState({
        inputValue: "",
      });
    } else {
      this.setState({
        selectedDate: date,
        inputValue: this.formatDate(date),
      });
    }
  }

  updateParentComponent(date) {
    if (
      (this.props.inputValue && !date) ||
      (!this.props.inputValue && date) ||
      (this.props.inputValue && this.props.inputValue !== date)
    ) {
      this.props.handleDateChange(date);
    }
  }

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      this.validateInput(this.state.inputValue);
    }
  };

  handleDateChange(date) {
    this.validateInput(date);
  }

  renderInfoButton() {
    if (this.props.info) {
      return <InfoButton description={this.props.info} />;
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.inputValue && !prevProps.inputValue) ||
      (!this.props.inputValue && prevProps.inputValue) ||
      (this.props.inputValue && this.props.inputValue !== prevProps.inputValue)
    ) {
      this.updateState(this.props.inputValue);
    }
  }

  render() {
    const validationMessage =
      this.state.invalidDateFormat ||
      this.state.invalidDateRange ||
      (this.props.isInvalid && this.props.errorMsg);
    return (
      <React.Fragment>
        {this.props.title && (
          <div className="title">
            {this.props.title}{" "}
            {!this.props.isMandatory && (
              <span className="optional-info">optional</span>
            )}{" "}
            {this.renderInfoButton()}
          </div>
        )}
        <div className="datepicker-component-container">
          <div
            className={`date-input-container ${
              validationMessage ? "invalid" : ""
            }`}
          >
            <div id="openHere" className="datepicker-input-container">
              <input
                className="form-control"
                type="text"
                placeholder="TT.MM.JJJJ"
                value={this.state.inputValue}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                onKeyPress={this.handleEnterKeyPress}
                disabled={this.props.disabled}
              />
            </div>
            <div className="datepicker-icon-wrapper">
              <div className="datepicker-icon">
                <Datepicker
                  id="creationDate"
                  ref={(c) => this.setCalendarObj(c)}
                  customInput={
                    <DatepickerButtonComponent
                      onBlur={() => {
                        this.onDateBlur();
                      }}
                      calendar={this.state.calendarObj}
                      calendarIcon={
                        !this.props.isDatepickerLocked
                          ? calendarIcon
                          : calendarLockedIcon
                      }
                    />
                  }
                  showYearDropdown
                  scrollableYearDropdown
                  popperPlacement="bottom-end"
                  selected={this.props.inputValue}
                  onSelect={this.handleDateChange}
                  minDate={this.props.minDate}
                  maxDate={this.props.maxDate}
                  openToDate={this.props.openToDate}
                  disabled={this.props.disabled}
                  calendarStartDay={this.props.calendarStartDay}
                  locale={de}
                />
              </div>
            </div>
          </div>
          {validationMessage && (
            <span className="datepicker-error-message">
              {this.state.invalidDateFormat
                ? "Bitte geben Sie das Datum in einem korrekten Format (TT.MM.JJJJ) ein."
                : this.state.invalidDateRange
                ? `Bitte geben Sie ein Datum ein, das zwischen dem ${this.formatDate(
                    this.props.minDate
                  )} und ${this.formatDate(this.props.maxDate)} liegt.`
                : this.props.errorMsg}
            </span>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DatepickerComponent;

DatepickerComponent.propTypes = {
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleDateChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  errorMsg: PropTypes.string,
  openToDate: PropTypes.object,
  isDatepickerLocked: PropTypes.bool,
};

/* INFO: implemented inline component because the react-datepicker can't handle the clicks and other props directly */
/* eslint-disable react/no-multi-comp */
export class DatepickerButtonComponent extends React.PureComponent {
  static propTypes = {
    calendar: PropTypes.object,
    calendarIcon: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCalendarOpen: false,
    };
  }

  toggle() {
    this.props.calendar.setOpen(!this.state.isCalendarOpen);
    this.setState({
      isCalendarOpen: !this.state.isCalendarOpen,
    });
  }

  handleClick() {
    this.toggle();
  }

  handleBlur() {
    this.setState({
      isCalendarOpen: false,
    });
  }

  render() {
    return (
      <div
        id="dateContainer"
        role="button"
        title="Kalender"
        tabIndex={0}
        onClick={() => this.handleClick()}
        onBlur={() => this.handleBlur()}
        className="datepicker-icon-container"
      >
        <img
          className="calendar-icon-image"
          alt=""
          src={this.props.calendarIcon}
        />
      </div>
    );
  }
}
