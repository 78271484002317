import XLSX from "xlsx";
import { receiverType as receiverTypeEnum } from "../../../Utils/constants";

const receiverFieldsMapping = {
  lastName: "Nachname",
  firstName: "Vorname",
  personalId: "Personal ID",
  company: "Unternehmen",
  email: "E-Mail-Adresse",
};

let headerMapping;

export const importReceivers = (file, receiverType) => {
  setHeaderColumns(receiverType);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      try {
        const sheetData = readExcelFile(e.target.result);
        const receivers = readReceivers(sheetData);
        resolve(receivers);
      } catch (error) {
        reject(error);
      }
    };
  });
};

const setHeaderColumns = (receiverType) => {
  headerMapping = JSON.parse(JSON.stringify(receiverFieldsMapping));
  // remove the header columns which are not needed depending receiverType
  if (receiverType === receiverTypeEnum.employee.value) {
    delete headerMapping.company; // company field is not required in case of bussinesPartner
  }
  if (receiverType === receiverTypeEnum.bussinesPartner.value) {
    delete headerMapping.personalId; // personalId field is not required in case of employee
  }
};

const readExcelFile = (fileData) => {
  var data = new Uint8Array(fileData);
  var workbook = XLSX.read(data, { type: "array", cellDates: true });
  var first_sheet_name = workbook.SheetNames[0];
  var worksheet = workbook.Sheets[first_sheet_name];

  let sheetData = XLSX.utils.sheet_to_json(worksheet, {
    header: 1,
    defval: "",
    blankrows: true,
    dateNF: "dd.mm.yyyy",
  });

  return sheetData;
};

const readReceivers = (sheetData) => {
  const receivers = [];
  let header;
  sheetData.forEach((row, index) => {
    if (index === 0) {
      header = row;
      if (!checkHeader(header)) {
        throw new Error("Header of file is invalid");
      }
    } else {
      const receiver = readReceiver(header, row);
      if (Object.values(receiver).some((value) => value)) {
        receivers.push(receiver);
      }
    }
  });

  return receivers;
};

const readReceiver = (header, row) => {
  const receiver = {};
  for (const [key, value] of Object.entries(headerMapping)) {
    receiver[key] = getFieldValue(header, row, value);
  }

  return receiver;
};

const getFieldValue = (header, values, fieldName) => {
  const fieldIndex = header.indexOf(fieldName);
  if (fieldIndex >= 0 && fieldIndex < values.length) {
    return values[fieldIndex]
      ? values[fieldIndex].toString().trim()
      : values[fieldIndex];
  }
  return undefined;
};

const checkHeader = (header) => {
  for (const column of Object.values(headerMapping)) {
    if (header.indexOf(column) < 0) {
      return false;
    }
  }

  return true;
};
