import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import "./_style.scss";
import { trackPI } from "../../../../Utils/utils";

function handleLogoutClick(props) {
  const { userProfile } = props;
  trackPI({
    pageId: "logout",
    content: "Abmelden",
    requrl: "/app/logout/click",
    siteid: props.siteid,
    event: props.event,
  });
  if (!userProfile.roles || userProfile.roles.includes("admin")) {
    window.location = "/logout";
  } else {
    window.location = "/api/auth/logout";
  }
}

function UserMenu(props) {
  return (
    <div ref={props.userMenuRef} className="profile-menu__container">
      {!props.userProfile.guestUser && (
        <a
          onClick={props.closeUserMenu}
          rel="noopener noreferrer"
          target="_blank"
          href="https://account.haufe-lexware.com/meinkonto/startseite/"
        >
          Mein Konto
        </a>
      )}
      <a
        onClick={props.closeUserMenu}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.haufe.de/forms/kontakt.html"
      >
        Support
      </a>
      {props.userMenuActions.map((action, index) => {
        return (
          <span
            key={`user-menu-action-${index}`}
            onClick={() => {
              props.closeUserMenu();
              action.onClick();
            }}
          >
            {action.title}
          </span>
        );
      })}
      <Route
        render={() => (
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              handleLogoutClick(props);
              this.props.closeUserMenu();
            }}
          >
            Abmelden
          </div>
        )}
      />
    </div>
  );
}

UserMenu.defaultProps = {
  userMenuActions: [],
};

UserMenu.propTypes = {
  userMenuRef: PropTypes.func,
  closeUserMenu: PropTypes.func,
  userMenuActions: PropTypes.array,
};

export default UserMenu;
