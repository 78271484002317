import React from "react";
import PropTypes from "prop-types";
import "./_style.scss";
function Label(props) {
  return props.label || props.value ? (
    <div className="label-container" id={props.id}>
      <label htmlFor={props.label}>{props.label}</label>
      {props.value && <label htmlFor={props.value}>{props.value}</label>}
    </div>
  ) : (
    ""
  );
}

Label.propTypes = {
  props: PropTypes.object,
};
export default Label;
