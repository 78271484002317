import "./style.scss";

import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";

import { allowedHtmlTagsForCustomerInfo } from "../../Utils/constants";
import sanitizeHtml from "sanitize-html";

function Imprint(props) {
  const { apiStore } = props.rootStore;
  const [imprintContent, setImprintContent] = useState(
    sanitizeHtml(apiStore.imprint, allowedHtmlTagsForCustomerInfo)
  );

  useEffect(() => {
    setImprintContent(
      sanitizeHtml(apiStore.imprint, allowedHtmlTagsForCustomerInfo)
    );
  }, [apiStore.imprint]);

  window.scrollTo(0, 1);
  return (
    <div
      className="imprint"
      dangerouslySetInnerHTML={{ __html: imprintContent }}
    /> // eslint-disable-line
  );
}

export default inject("rootStore")(observer(Imprint));
