import { detectIE } from "./checkBrowser";
import { rootStore } from "../index";

import {
  CANCEL_BUTTON,
  LEAVE_BUTTON,
  LEAVE_PAGE_TITLE,
  MAX_APPROVER_CHANGES,
  UNSAVED_CHANGES_LEAVE_TEXT,
  siteid,
  event,
  isTestEnvironment,
  productVideos,
  activeArea,
  actions,
} from "./constants";
import { isEmpty, trackPI } from "../sma-shared/Utils/utils";

export const isSameStringArray = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }
  for (const item of array1) {
    if (!array2.includes(item)) return false;
  }
  return true;
};

export const copyToClipboard = (text) => {
  if (detectIE()) {
    // service worker is not available for IE11, so we will use clipboardData
    if (window.clipboardData) {
      window.clipboardData.setData("Text", text);
    } else {
      window.alert("This browser does not support clipboardData");
    }
  } else {
    window.navigator.clipboard.writeText(text);
  }
};

export const addClass = (selector, className) => {
  document.querySelector(selector).classList.add(className);
};

export const removeClass = (selector, className) => {
  document.querySelector(selector).classList.remove(className);
};

export const stripHtml = (textContent) => {
  if (typeof textContent === "string") {
    return textContent.replaceAll("<[^>]*>", "");
  }
};
let historyChanged;
let browserHistory;
export const historyListener = (history) => {
  if (!historyChanged) {
    browserHistory = history;
    historyChanged = history.listen((location, action) => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  }
};

export const changeHistoryLocation = (path) => {
  if (browserHistory) {
    browserHistory.push(path);
  }
};

export const showUnsavedChangesModal = (
  modalStore,
  targetLocation,
  allowUserToChangeRoute
) => {
  modalStore.showChoice(
    LEAVE_PAGE_TITLE,
    UNSAVED_CHANGES_LEAVE_TEXT,
    LEAVE_BUTTON,
    () => allowUserToChangeRoute(targetLocation),
    CANCEL_BUTTON,
    () => {}
  );
};
export const checkPasswordStrength = (password) => {
  let passwordStrength = {
    length: false,
    noWhiteSpace: false,
    lowerCase: false,
    upperCase: false,
    numbers: false,
    specialCharacters: false,
  };

  if (typeof password === "string") {
    const regExWhiteSpace = new RegExp("\\s+");
    const regExLowerCase = new RegExp("[a-z]");
    const regExUpperCase = new RegExp("[A-Z]");
    const regExNumbers = new RegExp("\\d+");
    const regSpecialCharacters = new RegExp(
      `[~!@#$%^&*()_\\-+={}[\\]|:;'"<,>.?]`
    );

    passwordStrength.length = password.length > 7 && password.length < 31;
    passwordStrength.noWhiteSpace = !regExWhiteSpace.test(password);
    passwordStrength.lowerCase = regExLowerCase.test(password);
    passwordStrength.upperCase = regExUpperCase.test(password);
    passwordStrength.numbers = regExNumbers.test(password);
    passwordStrength.specialCharacters = regSpecialCharacters.test(password);
  }

  return passwordStrength;
};

export const showErrorInView = (errorClassName = "invalid") => {
  scrollIntoViewByClassName(errorClassName);
};

export function scrollIntoViewByClassName(
  className,
  scrollIntoViewOptions = { behavior: "smooth", block: "center" }
) {
  setTimeout(() => {
    const element = document.getElementsByClassName(className)[0];
    if (element) {
      var elementBounding = element.getBoundingClientRect();
      // scroll only if the element is not in visible area
      if (
        elementBounding.top < 0 ||
        elementBounding.top > window.innerHeight - 170
      ) {
        element.scrollIntoView(scrollIntoViewOptions);
      }
    }
  }, 200);
}

export function scrollIntoViewById(
  elementId,
  scrollIntoViewOptions = { behavior: "smooth", block: "start" }
) {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      var elementBounding = element.getBoundingClientRect();
      // scroll only if the element is not in visible area
      if (
        elementBounding.top < 0 ||
        elementBounding.bottom > window.innerHeight - 170
      ) {
        element.scrollIntoView(scrollIntoViewOptions);
      }
    }
  }, 200);
}

export const getEmailDomain = (email) => {
  if (typeof email === "string") {
    return email.substring(email.indexOf("@"));
  }
};

export const containsText = (source, searchText) => {
  if (source !== undefined) {
    if (typeof source === "object") {
      return Object.values(source).some((value) =>
        containsText(value, searchText)
      );
    }
    return source
      .toString()
      .toLowerCase()
      .includes(searchText.toString().toLowerCase());
  }
  return false;
};

export const getCorrectCentury = (yearEnding) => {
  let currentYear = new Date().getFullYear().toString();
  currentYear = currentYear.slice(0, 2);
  const year = currentYear + yearEnding;
  return year;
};

export const getRoleBasedProductVideos = (roles = []) =>
  productVideos.filter(
    (productVideo) =>
      !productVideo.roles ||
      roles.some((role) => productVideo.roles.includes(role))
  );

export const getFullName = (userInfo) =>
  `${userInfo.FirstName ? userInfo.FirstName : ""} ${
    userInfo.LastName ? userInfo.LastName : ""
  }`;
export const hasRole = (rolesList, role) => rolesList.includes(role);
export const areTooManyApproverChanges = (numberOfApproverChanges) =>
  isEmpty(numberOfApproverChanges)
    ? false
    : numberOfApproverChanges > MAX_APPROVER_CHANGES;

export const getGtmInfoForStep = (step) => {
  let gtmArea, gtmAction, gtmDescription;
  switch (step) {
    case "CompliancePage":
      gtmArea = activeArea.settings;
      gtmAction = actions.upload;
      gtmDescription = "Guideline"; // GM_Settings_Upload_Guideline
      break;
    case "update-gift-data":
      gtmArea = activeArea.gift;
      gtmAction = actions.upload;
      gtmDescription = "Invoice"; // GM_Gift_Upload_Invoice
      break;
    case "update-financial-accounting-data":
      gtmArea = activeArea.accounting;
      gtmAction = actions.upload;
      gtmDescription = "Invoice"; // GM_Accounting_Upload_Invoice
      break;
    default:
      return;
  }
  return { gtmArea, gtmAction, gtmDescription };
};

export const getGtmIdInfoFromText = (helpTextId, dataLength) => {
  switch (helpTextId) {
    case "help-text-settings-employee-taxe":
      return {
        activeArea: activeArea.settings,
        action: actions.click,
        description: "InfoFlattaxEmployees",
      };
    case "help-text-settings-business-partner-tax":
      return {
        activeArea: activeArea.settings,
        action: actions.click,
        description: "InfoFlattaxBusinessPartner",
      };
    case "help-text-settings-deduct-tax":
      return {
        activeArea: activeArea.settings,
        action: actions.click,
        description: "InfoVATDeduction",
      };
    case "help-text-receiver-type":
      return {
        activeArea: activeArea.entryCheck,
        action: actions.click,
        description: "InfoIcon",
      };
    case "help-text-receiver-number-of-receivers":
      return {
        activeArea: activeArea.recipient,
        action: actions.click,
        description: "InfoNumber",
      };
    case "help-text-receiver-receiver-personal-data":
      return {
        activeArea: activeArea.recipient,
        action: actions.click,
        description: "InfoPersonData",
      };
    case "help-text-gift-info":
      return {
        activeArea: activeArea.gift,
        action: actions.click,
        description: "InfoGift",
      };
    case "help-text-gift-invoice":
      return dataLength === 2
        ? {
            activeArea: activeArea.gift,
            action: actions.click,
            description: "InfoInvoice",
          }
        : {
            activeArea: activeArea.accounting,
            action: actions.click,
            description: "InfoInvoice",
          };
    default:
      return {};
  }
};

export const getGtmIdDescriptionFromButtonValue = (
  buttonValue,
  defaultDescription
) => {
  if (buttonValue) {
    if (buttonValue === "employee" || buttonValue === "bussinesPartner") {
      return {
        activeArea: activeArea.entryCheck,
        action: actions.select,
        description: "RecipientType" + buttonValue,
      };
    } else if (buttonValue === "one" || buttonValue === "multiple") {
      return {
        activeArea: activeArea.recipient,
        action: actions.select,
        description: "Number" + buttonValue,
      };
    }
  } else {
    return {
      activeArea: activeArea.settings,
      action: actions.select,
      description: defaultDescription,
    };
  }
  return;
};

export const getGtmInfoFromButtonName = (buttonName, giftDate) => {
  if (buttonName === "Bericht exportieren")
    return {
      activeArea: activeArea.report,
      action: actions.download,
      description: "PDF",
    };
  if (buttonName === "Weiter zur Genehmigung")
    return {
      activeArea: activeArea.gift,
      action: actions.select,
      description: getGtmDescriptionForDate(giftDate),
    };

  let area, action;
  if (buttonName) {
    switch (buttonName) {
      case "Zurück zur Fallübersicht":
        area = activeArea.entryCheck;
        action = actions.click;
        break;
      case "Zurück zum Einstiegs-Check":
        area = activeArea.recipient;
        action = actions.click;
        break;
      case "Zurück zu Empfänger":
        area = activeArea.gift;
        action = actions.click;
        break;
      case "Zurück zum Geschenk":
        area = activeArea.approval;
        action = actions.click;
        break;
      case "Zurück zur Genehmigung":
        area = activeArea.accounting;
        action = actions.click;
        break;
      case "Zurück zur Bearbeitung Fibu":
        area = activeArea.payroll;
        action = actions.click;
        break;
      case "Zurück zur Bearbeitung Entgeltabrechnung":
        area = activeArea.report;
        action = actions.click;
        break;
      default:
        return;
    }
  }
  return { activeArea: area, action: action, description: "Back" };
};

export const getGtmDescriptionForDate = (date) => {
  const currentDate = new Date();
  return date < currentDate ? "PastGift" : "FutureGift";
};

export const getGtmIdInfoForActionFromStep = (stepId) => {
  let area, action;
  if (stepId) {
    switch (stepId) {
      case 2: // GM_EntryCheck_Select_Save
        area = activeArea.entryCheck;
        action = actions.select;
        break;
      case 3: // GM_Receipient_Select_Save
        area = activeArea.recipient;
        action = actions.select;
        break;
      case 4: // GM_Gift_Select_Save
        area = activeArea.gift;
        action = actions.select;
        break;
      case 5: // GM_Approval_Select_Save
        area = activeArea.approval;
        action = actions.select;
        break;
      case 6: // GM_Accounting_Select_Save
        area = activeArea.accounting;
        action = actions.select;
        break;
      case 7: // GM_Payroll_Select_Save
        area = activeArea.payroll;
        action = actions.select;
        break;
      case 8: // GM_Report_Select_Save
        area = activeArea.report;
        action = actions.select;
        break;
      default:
        return;
    }
  }
  return { gtmActiveArea: area, gtmAction: action };
};

// add application parameters to sst tracking event
export function sstTrackingHelper(pageId, content, requrl, isExternalUser) {
  if (isTestEnvironment || isExternalUser) {
    trackPI({ pageId, content, requrl, event, siteid });
  }
}

export function shouldTrack(isExternalUser) {
  return isExternalUser || isTestEnvironment;
}

export function shouldTrackMixpanel() {
  const isExternalUser = rootStore.apiStore.userInfo?.isExternalUser;
  return isExternalUser || isTestEnvironment;
}

export function generateGtmEventName(area, action, description) {
  if (shouldTrackMixpanel()) {
    const userRoles = rootStore.apiStore.userInfo.roles.length
      ? rootStore.apiStore.userInfo.roles
      : "none";
    return `GM_${area}_${action}_${description}_${userRoles}`;
  }
}
