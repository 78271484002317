import React, { useState } from "react";
import "../_style.scss";
import { inject } from "mobx-react";
import { showErrorInView } from "../../../Utils/utils";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import {
  validateEmail,
  isEmpty,
  focusElement,
} from "../../../sma-shared/Utils/utils";
import {
  RESET_PASSWORD,
  REGISTRATION,
  ROOT_PATH,
  LOGIN,
} from "../../../Utils/paths";
import {
  EMAIL_ERROR,
  PASSWORD_ERROR,
  EMAIL_TEXT,
  LOGIN_TITLE_TEXT,
  LOGIN_DESCRIPTION_TEXT,
  LOGIN_TEXT,
  YOUR_PASSWORD_TEXT,
  PASSWORD_FORGOT_TEXT,
  NEW_REGISTRATION_DESCRIPTION_TEXT,
  REGISTRATION_NOW_TEXT,
  LOGIN_TEXT_BUTTON,
  REGISTRATION_TEXT,
  NEXT_BUTTON_TEXT,
  BACK_TO_EMAIL_TEXT_BUTTON,
  ERROR_MESSAGE_LOGIN,
} from "../labels";

function Login(props) {
  const { apiStore } = props.rootStore;
  const { history } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const [loginWithSuccess, setLoginWithSuccess] = useState();

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [isUserFromCognito, setIsUserFromCognito] = useState(false);

  const validate = () => {
    const isEmailValid = validateEmail(email);
    setIsValidEmail(isEmailValid);

    const isPasswordValid = !isEmpty(password);
    setIsValidPassword(isPasswordValid);

    if (!isEmailValid || !isPasswordValid) {
      showErrorInView();
    }

    return isEmailValid && isPasswordValid;
  };

  const backToEmailClicked = () => {
    setIsUserFromCognito(false);
    setPassword("");
  };

  const loginClicked = () => {
    if (validate()) {
      apiStore.login({ username: email, password }, (result) => {
        if (result.error) {
          const message = ERROR_MESSAGE_LOGIN(result.error.code);
          setLoginMessage(message);
          setLoginWithSuccess(false);
          showErrorInView("error");
        } else if (result) {
          if (window.location.href.includes(LOGIN)) {
            window.location = ROOT_PATH;
          } else {
            window.location.reload();
          }
        }
      });
    }
  };

  const nextLoginStep = (isStepValid) => {
    if (!isEmpty(email) && isStepValid) {
      apiStore.verifyEmail(email, (result) => {
        if (!isEmpty(result.redirect)) {
          if (result.redirect === "/auth/login") {
            setIsUserFromCognito(true);
            focusElement("input.form-control.password");
            setLoginMessage("");
          } else {
            window.location = result.redirect;
          }
        }
      });
    }
  };

  const renderLoginSection = () => {
    const inputs = [];
    inputs.push({
      type: INPUT_CARD_TYPE.text,
      label: EMAIL_TEXT,
      isMandatory: true,
      placeholder: " ",
      value: email,
      disabled: isUserFromCognito,
      isInvalid: !isValidEmail,
      errorMsg: EMAIL_ERROR,
      onChange: (e) => {
        // don't allow email change after pressing WEITER
        if (!isUserFromCognito) {
          setEmail(e.target.value);
          if (!isValidEmail)
            setIsValidEmail(
              isEmpty(e.target.value) ? true : validateEmail(e.target.value)
            );
        }
      },
      onBlur: (e) => {
        setEmail(e.target.value);
        setIsValidEmail(e.target.value ? validateEmail(e.target.value) : true);
      },
      onKeyDown: (e) => {
        if (e.key === "Enter") {
          setIsValidEmail(
            e.target.value ? validateEmail(e.target.value) : true
          );
          nextLoginStep(validateEmail(email));
        }
      },
    });
    if (!isUserFromCognito) {
      inputs.push({
        type: INPUT_CARD_TYPE.customElement,
        customElement: <div className="divider-line" />,
      });
      inputs.push({
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderNextButton(),
      });
    }
    if (isUserFromCognito) {
      inputs.push(
        {
          type: INPUT_CARD_TYPE.password,

          label: YOUR_PASSWORD_TEXT,
          isMandatory: true,
          placeholder: " ",
          value: password,
          isInvalid: !isValidPassword,
          errorMsg: PASSWORD_ERROR,
          onChange: (e) => {
            setPassword(e.target.value);
            setIsValidPassword(!isEmpty(e.target.value));
          },
          onKeyDown: (e) => {
            if (e.key === "Enter") {
              setPassword(e.target.value);
              setIsValidPassword(!isEmpty(e.target.value));
              loginClicked();
            }
          },
        },
        {
          type: INPUT_CARD_TYPE.customElement,
          customElement: renderLoginButtons(),
        }
      );
    }

    return (
      <React.Fragment>
        <InputCard title={LOGIN_TEXT} data={inputs} />
      </React.Fragment>
    );
  };

  const renderSignupSection = () => {
    const inputs = [
      {
        type: INPUT_CARD_TYPE.label,
        label: NEW_REGISTRATION_DESCRIPTION_TEXT,
      },
      {
        type: INPUT_CARD_TYPE.customElement,
        customElement: (
          <button
            onClick={() => {
              history.push(REGISTRATION);
            }}
            className="btn btn-transparent"
          >
            {REGISTRATION_NOW_TEXT}
          </button>
        ),
      },
    ];

    return (
      <React.Fragment>
        <InputCard title={REGISTRATION_TEXT} data={inputs} />
      </React.Fragment>
    );
  };

  const renderNextButton = () => {
    return (
      <React.Fragment>
        <div className="next-action">
          <button
            onClick={() => nextLoginStep(validateEmail(email))}
            className="btn btn-primary right"
          >
            {NEXT_BUTTON_TEXT}
          </button>
        </div>
      </React.Fragment>
    );
  };

  const renderLoginButtons = () => {
    return (
      <React.Fragment>
        <div className="login-box-buttons">
          <div className="forgot-password-actions">
            <button
              className="text-button"
              onClick={(e) => {
                history.push(RESET_PASSWORD);
              }}
            >
              {PASSWORD_FORGOT_TEXT}
            </button>
          </div>
          <div className="divider-line" />
          <div className="login-actions">
            <button
              className="btn btn-back left"
              onClick={() => backToEmailClicked()}
            >
              {BACK_TO_EMAIL_TEXT_BUTTON}
            </button>
            <button
              className="btn btn-primary right"
              onClick={() => loginClicked()}
            >
              {LOGIN_TEXT_BUTTON}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="auth-container">
      <div className="left-container">
        <div className="form">
          <div className="page-title">{LOGIN_TITLE_TEXT}</div>
          <div className="description">{LOGIN_DESCRIPTION_TEXT}</div>
          <div className="sections-container">{renderLoginSection()}</div>
          {loginMessage && (
            <div
              className={`message ${loginWithSuccess ? "success" : "error"}`}
            >
              {loginMessage}
            </div>
          )}
          <div className="sections-container">{renderSignupSection()}</div>
        </div>
      </div>
      <div className="right-container"></div>
    </div>
  );
}

export default inject("rootStore")(Login);
