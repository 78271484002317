import React, { useState, useEffect } from "react";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import { validateEmail, isEmpty } from "../../../sma-shared/Utils/utils";
import { getEmailDomain } from "../../../Utils/utils";
import { INVALID_EMAIL_DOMAIN_ERROR_MESSAGE } from "../../../Utils/constants";
import {
  APPROVAL_COMMENT,
  APPROVAL_EMAIL,
  APPROVAL_INFO,
  ERROR_MESSAGE_EMAIL,
} from "../giftCaseLabels";
import "./_style.scss";

function RequestApproval(props) {
  const { emailSuggestions } = props;
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestStyle, setSuggestStyle] = useState({});

  useEffect(() => {
    window.addEventListener("resize", calculateSuggestWidth());
    calculateSuggestWidth();
    // eslint-disable-next-line
  }, []);

  const checkEmail = (email) => {
    const isEmailValid = validateEmail(email);
    if (isEmailValid) {
      const enteredEmailDomain = getEmailDomain(email);
      return props.companyDomain.includes(enteredEmailDomain);
    }
    return isEmailValid;
  };

  const calculateSuggestWidth = () => {
    const input = document.getElementsByClassName(
      "react-autosuggest__container"
    );
    if (input.length > 0)
      setSuggestStyle({
        width: input[0].clientWidth - 30 + "px",
      });
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const allFoundSuggestions =
      inputLength === 0
        ? []
        : emailSuggestions.filter((email) =>
            email.toLowerCase().includes(inputValue)
          );
    return allFoundSuggestions;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const renderSuggestion = (suggestion) => (
    <div style={suggestStyle}>{suggestion}</div>
  );

  const inputProps = {
    className: `form-control ${isEmailInvalid ? "invalid" : ""}`,
    placeholder: "",
    value: email,
    onChange: (event, value) => {
      setEmail(value.newValue);
    },
    onBlur: () => {
      setIsEmailInvalid(!isEmpty(email) && !checkEmail(email));
    },
  };

  const renderInputFields = () => [
    {
      type: INPUT_CARD_TYPE.autosuggestInput,
      id: "email-autosuggest",
      label: APPROVAL_EMAIL,
      errorMsg: !validateEmail(email)
        ? ERROR_MESSAGE_EMAIL
        : INVALID_EMAIL_DOMAIN_ERROR_MESSAGE,
      isInvalid: isEmailInvalid,
      isMandatory: true,
      suggestions: suggestions,
      onSuggestionsFetchRequested: onSuggestionsFetchRequested,
      onSuggestionsClearRequested: onSuggestionsClearRequested,
      renderSuggestion: renderSuggestion,
      inputProps: inputProps,
    },
    {
      type: INPUT_CARD_TYPE.textArea,
      label: APPROVAL_COMMENT,
      value: comment,
      id: "senderComment",
      isMandatory: false,
      placeholder: " ",
      onChange: (e) => {
        setComment(e.target.value);
      },
    },
  ];

  const onSendButtonClicked = () => {
    if (isEmpty(email)) {
      setIsEmailInvalid(true);
    } else {
      if (!isEmailInvalid) {
        props.sendToApprover(
          email.toLowerCase(),
          comment.replace(/(<([^>]+)>)/gi, "")
        );
      }
    }
  };

  return (
    <div>
      <div key="description">{APPROVAL_INFO}</div>
      <InputCard key="inputFields" data={renderInputFields()} />
      <div className="buttons-area">
        <button className="btn-ghost primary-button" onClick={props.closeModal}>
          Abbrechen
        </button>
        <button
          className={`btn btn-primary`}
          onClick={() => onSendButtonClicked()}
        >
          Senden
        </button>
      </div>
    </div>
  );
}

export default RequestApproval;
