import React from "react";
import closeIcon from "../../Assets/svg/close-icon-blue.svg";
import Modal from "react-modal";
import { inject, observer } from "mobx-react";
import "./_style.scss";

class InfoBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.close = this.close.bind(this);
  }

  close() {
    this.props.rootStore.infoBoxStore.hideInfoBox();
  }

  /* no real use at the moment*/
  componentDidUpdate() {
    const { infoBoxStore } = this.props.rootStore;
    setTimeout(() => {
      if (infoBoxStore.infoBoxVisible && infoBoxStore.primaryButton) {
        infoBoxStore.primaryButton.focus();
      }
    }, 100);
  }

  render() {
    const { infoBoxStore } = this.props.rootStore;
    return (
      <Modal
        isOpen={infoBoxStore.infoBoxVisible}
        onRequestClose={this.close}
        shouldCloseOnOverlayClick={infoBoxStore.shouldCloseOnOverlayClick}
        className="modal-info"
        overlayClassName="backdrop"
        ariaHideApp={false}
      >
        <div className="info-box-container">
          <div className="gradient-line background-gradient1" />
          <span
            onClick={() => this.close()}
            className="close-icon-container"
            role="button"
            tabIndex="-1"
          >
            <img className="close-icon-image" alt="" src={closeIcon} />
          </span>
          <div className="info-text-container">
            <div className="info-header-container">{infoBoxStore.title}</div>
            <div className="info-details-text-container">
              {infoBoxStore.content}
              {infoBoxStore.buttons && (
                <div className="accept-btn-container">
                  {infoBoxStore.buttons}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(InfoBox));
