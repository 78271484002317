import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import interact from "interactjs";
import FormattedText from "../FormattedText";
import closeSVG from "../../Assets/svg/close.svg";
import "./_style.scss";

class HelpWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    const helpWindow = document.querySelector("#helpWindow");
    const cardContainer = document.querySelector(".App");

    if (helpWindow)
      interact(helpWindow)
        .draggable({
          // ignore elements
          ignoreFrom:
            ".help-window__title, .help-window__content-container, button",
          onmove: this.dragMoveListener,
          // restrict dragging outside card container
          restrict: {
            restriction: cardContainer,
            elementRect: {
              top: 0,
              left: 0,
              bottom: 1,
              right: 1,
            },
          },
        })
        .resizable({
          // resize from all edges and corners
          edges: {
            left: true,
            right: true,
            bottom: true,
            top: true,
          },

          // keep the edges inside the parent
          restrictEdges: {
            outer: cardContainer,
            endOnly: false,
          },
          // minimum size
          restrictSize: {
            min: {
              width: 100,
              height: 50,
            },
          },

          inertia: true,
        })
        // resize function
        .on("resizemove", function (event) {
          var target = event.target,
            x = parseFloat(target.getAttribute("data-x")) || 0,
            y = parseFloat(target.getAttribute("data-y")) || 0;

          // update the element's style
          target.style.width = event.rect.width + "px";
          target.style.height = event.rect.height + "px";

          // translate when resizing from top or left edges
          x += event.deltaRect.left;
          y += event.deltaRect.top;

          target.style.webkitTransform = target.style.transform =
            "translate(" + x + "px," + y + "px)";

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        });

    window.addEventListener("resize", () => {
      interact(helpWindow).draggable({
        restriction: cardContainer,
        elementRect: {
          top: 0,
          left: 0,
          bottom: 1,
          right: 1,
        },
      });
    });
  }

  dragMoveListener(event) {
    var target = event.target,
      // keep the dragged position in the data-x/data-y attributes
      x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx,
      y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.webkitTransform = target.style.transform =
      "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
  }

  close() {
    this.props.rootStore.modalStore.hideHelp();
  }

  render() {
    const className = this.props.rootStore.modalStore.helpVisible
      ? "help-window-container"
      : "help-window-container hidden";
    return (
      <div id="helpWindow" className={className}>
        <div className="help-window__title-bar">
          <div className="title-bar__container">
            <span className="help-window__title">
              {this.props.rootStore.modalStore.helpTitle}
            </span>
            <button className="help-window__close-button" onClick={this.close}>
              <img
                className="btn-icon-image close-icon"
                alt="Close"
                src={closeSVG}
              />
            </button>
          </div>
        </div>
        <div className="help-window__content-container">
          {
            <FormattedText
              text={this.props.rootStore.modalStore.helpText}
              onLinkClicked={this.props.rootStore.modalStore.onHelpLinkClicked}
            />
          }
        </div>
      </div>
    );
  }
}

HelpWindow.propTypes = {
  rootStore: PropTypes.object.isRequired,
};

export default inject("rootStore")(observer(HelpWindow));
