import React from "react";
import "./_style.scss";

function EmptyTable({ message }) {
  return (
    <div className="no-cases">
      <h5>{message}</h5>
    </div>
  );
}

export default EmptyTable;
