import { decorate, observable, action } from "mobx";
import settingsSchema from "../Resources/settings_empty.json";
import { isEmpty } from "../sma-shared/Utils/utils";
import { getEmailDomain } from "../Utils/utils";
import { overwriteChangesModal } from "../Utils/overwriteDialog";
import { UPDATE_STATUS } from "../Utils/constants";

export default class SettingsStore {
  apiStore;
  modalStore;

  idFromDB = "";
  refreshedAt = "";
  dataFromDB = "";

  // company
  name = "";
  domain = [];
  isEmployeeTaxCovered;
  isBusinessPartnerTaxCovered;
  isDeductionTax;

  accounting = [];
  payroll = [];

  // compliance
  url = "";
  fileAttachments = [];

  constructor(rootStore) {
    this.apiStore = rootStore.apiStore;
    this.modalStore = rootStore.modalStore;
  }

  reset() {
    this.idFromDB = "";
    this.refreshedAt = "";
    this.dataFromDB = "";
    this.name = "";
    this.domain = [getEmailDomain(this.apiStore.userInfo.Email)]; // by default is the email domain of current user
    this.isEmployeeTaxCovered = undefined;
    this.isBusinessPartnerTaxCovered = undefined;
    this.isDeductionTax = undefined;
    this.accounting = [];
    this.payroll = [];
    this.url = "";
    this.fileAttachments = [];
  }

  editSettings(settingsObject) {
    this.reset();
    if (!settingsObject) {
      return;
    }

    this.idFromDB = settingsObject._id;
    this.refreshedAt = settingsObject.status
      ? settingsObject.status.refreshedAt
      : "";
    if (settingsObject.company) {
      this.name = settingsObject.company.name;
      this.domain = settingsObject.company.domain;
      this.accounting = settingsObject.company.accounting;
      this.payroll = settingsObject.company.payroll;
      this.isEmployeeTaxCovered = settingsObject.company.isEmployeeTaxCovered;
      this.isBusinessPartnerTaxCovered =
        settingsObject.company.isBusinessPartnerTaxCovered;
      this.isDeductionTax = settingsObject.company.isDeductionTax;
    }

    if (settingsObject.compliance) {
      this.url = settingsObject.compliance.url;
      this.fileAttachments = settingsObject.compliance.fileAttachments
        ? JSON.parse(JSON.stringify(settingsObject.compliance.fileAttachments))
        : [];
    }
    this.updateDataFromDB();
  }

  saveSettings() {
    if (this.name && this.domain) {
      const schema = this.writeSettingsToSchema();
      this.apiStore.addOrUpdateSettings(
        schema,
        this.idFromDB,
        false,
        (data) => {
          if (data.message === UPDATE_STATUS.success) {
            this.editSettings(data.settings);
          } else {
            overwriteChangesModal(
              this.modalStore,
              data.message === UPDATE_STATUS.changedByOther,
              () => {
                this.editSettings(data.settings);
              },
              () => {
                this.apiStore.addOrUpdateSettings(
                  schema,
                  this.idFromDB,
                  true,
                  (data) => {
                    if (data.message === UPDATE_STATUS.success) {
                      this.editSettings(data.settings);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
  }

  isSettingsDataChanged() {
    if (this.dataFromDB) {
      const dbData = JSON.stringify(JSON.parse(this.dataFromDB));
      this.writeSettingsToSchema();
      const currentData = JSON.stringify(settingsSchema);

      return dbData !== currentData;
    }
    return true;
  }

  writeSettingsToSchema() {
    settingsSchema._id = this.idFromDB || null;
    settingsSchema.company.name = this.name;
    settingsSchema.company.domain = this.domain;
    settingsSchema.company.accounting = this.accounting;
    settingsSchema.company.payroll = this.payroll;
    settingsSchema.company.isEmployeeTaxCovered = this.isEmployeeTaxCovered;
    settingsSchema.company.isBusinessPartnerTaxCovered =
      this.isBusinessPartnerTaxCovered;
    settingsSchema.company.isDeductionTax = this.isDeductionTax;
    settingsSchema.compliance.url = this.url;
    settingsSchema.compliance.fileAttachments = JSON.parse(
      JSON.stringify(this.fileAttachments)
    );
    settingsSchema.status = { refreshedAt: this.refreshedAt };

    return settingsSchema;
  }

  updateDataFromDB() {
    this.writeSettingsToSchema();
    this.dataFromDB = JSON.stringify(settingsSchema);
  }

  discardChanges() {
    if (!isEmpty(this.dataFromDB)) {
      const dataFromDBObject = JSON.parse(this.dataFromDB);
      this.editSettings(dataFromDBObject);
    }
  }
}

decorate(SettingsStore, {
  // Observables
  name: observable,
  domain: observable,
  isEmployeeTaxCovered: observable,
  isBusinessPartnerTaxCovered: observable,
  isDeductionTax: observable,
  accounting: observable,
  payroll: observable,
  url: observable,
  fileAttachments: observable,

  // Actions
  editSettings: action,
  saveSettings: action,
  discardChanges: action,
});
