export const buildVersion = "Version: 1.0.BUILD_VERSION";
export const buildDate = "Stand: ";

export const APPLICATIONTITLE = "Geschenkemanager";
export const OVERVIEW = "Vorgänge";

export const AVV_DOCUMENT_NAME = "Geschenke-Manager_AVV_20211206.pdf";

export const HR_USER = "HR User";
export const DELEGATE_USER = "Mitarbeiter";

export const LEAVE_PAGE_TITLE = "Ungespeicherte Änderungen";
export const LEAVE_PAGE_MESSAGE = "Möchten Sie Ihre Änderungen speichern?";
export const UNSAVED_CHANGES_LEAVE_TEXT =
  "Sie haben ungespeicherte Änderungen. Wollen Sie diese Seite wirklich verlassen?";
export const LEAVE_BUTTON = "Verlassen";
export const CANCEL_BUTTON = "Abbrechen";
export const NO_BUTTON = "Nein";
export const OK_BUTTON = "OK";

export const RECEIVER_CHANGES_TITLE = "Empfänger löschen";
export const RECEIVER_CHANGES_MESSAGE =
  "Die Änderungen werden auch auf der folgenden Seite „Empfänger“ übernommen.";
export const MULTIPLE_TO_ONE_RECEIVER_CHANGES_MESSAGE =
  "Bei dieser Veränderung wird nur der erste Empfänger übernommen.";
export const MULTIPLE_RECEIVER_ERROR_MESSAGE =
  " Sie müssen mindestens einen Empfänger erfassen.";
export const UPLOAD_FILE_ERROR_MESSAGE =
  "Bitte speichern Sie die Einstellungen, bevor Sie eine Datei hochladen.";
export const INVALID_EMAIL_DOMAIN_ERROR_MESSAGE =
  "Die eingegebene E-Mail-Adresse passt nicht zur E-Mail-Domain Ihres Unternehmens.";

export const STATUS_OPEN = "offen";
export const STATUS_DELEGATED = "delegiert";
export const STATUS_EXPIRED = "abgelaufen";
export const STATUS_FINISHED = "abgeschlossen";

export const HEADER_FIX_HEIGHT = 66;
export const NAVBAR_FIX_HEIGHT = 76;
export const FOOTER_FIX_HEIGHT = 80;
export const STICKY_FIX_HEIGHT = 553;

export const MAX_SIZE = 25 * 1024 * 1024; // 25 MB
export const MAX_APPROVER_CHANGES = 4;
export const MAX_FILES_TO_UPLOAD = 5;
export const ALLOW_FILE_TYPES = [
  //WORD
  ".doc",
  ".dot",
  ".wbk",
  ".docx",
  ".docm",
  ".dotx",
  ".dotm",
  ".docb",
  ".rtf",
  ".pdf",
  // EXCEL
  ".xls",
  ".xlt",
  ".xlm",
  ".xlsx",
  ".xlsm",
  ".xltm",
  //POWER POINT
  ".ppt",
  ".pot",
  ".pps",
  ".pptx",
  ".pptm",
  ".potx",
  ".potm",
  ".ppam",
  ".ppsx",
  ".ppsm",
  ".sldx",
  ".sldm",
  // IMAGES
  ".jpg",
  ".gif",
  ".png",
];
export const OVERVIEW_HEADER_NAME = "Fallübersicht";
export const SETTINGS_HEADER_NAME = "Einstellungen";
export const EMPLOYEE_HEADER_NAME = "Mitarbeiter";

// export const getInvoiceFolderPath = id => {
//   return `${id}/gift/invoices`;
// };

// export const getComplianceFolderPath = id => {
//   return `${id}/settings/compliance`;
// };

export const FEATURE_TOUR_CONFIG = {
  hidePrev: true,
  prevLabel: "ZURÜCK",
  nextLabel: "NÄCHSTER TIPP",
  skipLabel: "",
  doneLabel: "SCHLIE&#7838EN",
  tooltipClass: "intro-tooltip-class",
  overlayOpacity: 0.6,
  exitOnOverlayClick: false,
  showStepNumbers: false,
  showBullets: false,
  keyboardNavigation: false,
};

export const numberOfReceiversType = {
  one: { label: "Einzelner Empfänger", value: "one" },
  multiple: { label: "Mehrere Empfänger", value: "multiple" },
};

export const receiverType = {
  employee: { label: "Mitarbeiter", value: "employee" },
  bussinesPartner: { label: "Geschäftspartner", value: "bussinesPartner" },
};

export const giftStatus = {
  draft: { label: "Entwurf", value: "draft" },
  approvalRequest: { label: "Bei Genehmiger", value: "approvalRequest" },
  approved: { label: "Genehmigt, an Fibu", value: "approved" },
  rejected: { label: "Abgelehnt", value: "rejected" },
  financialAccountingProcessing: {
    label: "Bei Fibu",
    value: "financialAccountingProcessing",
  },
  payrollProcessing: { label: "Bei Entgelt", value: "payrollProcessing" },
  completed: { label: "Abgeschlossen", value: "completed" },
};

export const status = {
  draft: "draft",
  approvalRequest: "approvalRequest",
  approved: "approved",
  rejected: "rejected",
  financialAccountingProcessing: "financialAccountingProcessing",
  payrollProcessing: "payrollProcessing",
  completed: "completed",
  empty: "",
};

export const reasonOptions = {
  withoutPersonalOccasion: {
    label: "Ohne besonderen persönlichen Anlass",
    value: "withoutPersonalOccasion",
    key: 1,
  },
  withPersonalOccasion: {
    label: "Mit besonderem persönlichen Anlass",
    value: "withPersonalOccasion",
    key: 2,
  },
};

export const ROLES = {
  admin: "admin",
  accounting: "accounting",
  payroll: "payroll",
};

export const ROLES_NAME_DE = {
  admin: "admin",
  accounting: "Fibu",
  payroll: "Entgeltabrechnung",
};

export const rolesStatus = {
  payroll: { label: "Entgeltabrechnung", value: ROLES.payroll },
  financialAccounting: { label: "Fibu", value: ROLES.accounting },
};

export const STATUS_MESSAGE_FIBU_PAYROLL = {
  unfinished: "noch nicht bearbeitet",
  finished: "bearbeitet",
};

export const STATUS_MESSAGE_APPROVAL = {
  "": "Noch nicht erteilt",
  approvalRequest: "Angefordert",
  approved: "Genehmigt",
  rejected: "Abgelehnt",
};

export const EditCellType = {
  text: "text",
  number: "number",
  percentage: "percentage",
  email: "email",
};

export const fillEmployerSettingsWarning =
  "Achtung: Bevor Sie den Geschenkemanager für Ihr Unternehmen nutzen können, müssen Sie die Einstellungen vervollständigen. Zusätzlich zu den Fragen nach der Übernahme der Pauschalsteuer nach § 37b EStG und der Vorsteuerabzugsberechtigung (s. Unternehmen) können Sie auch die Geschenke-Richtlinien Ihres Unternehmens verlinken bzw. hochladen (s. Compliance).";

export const GIFT_VALUE_TYPE = {
  gross: { label: "Brutto", value: "gross" },
  net: { label: "Netto", value: "net" },
};

export const giftStatusOverview = {
  draft: "Entwurf",
  approvalRequest: "bei Genehmiger",
  approved: "Genehmigt, an Fibu",
  rejected: "abgelehnt",
  financialAccountingProcessing: "bei Fibu",
  payrollProcessing: "bei Entgeltabrechnung",
  completed: "abgeschlossen",
};

const todayDate = new Date();
export const firstDayLastYearDate = new Date(todayDate.getFullYear() - 1, 0, 1);
export const lastDayNextYearDate = new Date(
  todayDate.getFullYear() + 1,
  11,
  31
);

export const USER_STATUS_INACTIVE = "inactive";
export const allowedHtmlTagsForCustomerInfo = {
  allowedTags: [
    "h",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "p",
    "a",
    "br",
    "strong",
    "hr",
    "ul",
    "li",
  ],
  allowedAttributes: { a: ["href"] },
};

export const NO_LICENCE_PI = "allowUserWithoutLicense";

export const UPDATE_STATUS = {
  success: "success",
  notAllow: "not allow",
  removed: "removed",
  changedByOther: "changed by other user",
};
export const productVideos = [
  {
    title: "Vorteile für Finanzbuchhaltung und Entgeltabrechnung",
    shortTitle: "Video: Korrekt abrechnen",
    source: "https://playout.3qsdn.com/b8f7d455-2a68-11ec-b839-0cc47a188158",
    roles: [ROLES.admin, ROLES.payroll, ROLES.accounting],
  },
  {
    title: "Erleichterung für die Schenker im Unternehmen",
    shortTitle: "Video: Richtig schenken",
    source: "https://playout.3qsdn.com/b8f0e9b1-2a68-11ec-b839-0cc47a188158",
  },
];

export const EMAIL_SEND_SUCCESS = "Die Nachricht wurde erfolgreich gesendet.";

export const siteid = "Geschenkemanager";
export const event = "GM_custom_event";
export const isTestEnvironment = window.location.hostname !== "gm.haufe.de";

export const actions = {
  navigate: "Navigate",
  click: "Click",
  open: "Open",
  sort: "Sort",
  hover: "Hover",
  select: "Select",
  deselect: "Deselect",
  download: "Download",
  remove: "Remove",
  confirm: "Confirm",
  start: "Start",
  create: "Create",
  expand: "Expand",
  insert: "Insert",
  upload: "Upload",
};

export const activeArea = {
  caseOverview: "CaseOverview",
  settings: "Settings",
  report: "Report",
  startpage: "Startpage",
  entryCheck: "EntryCheck",
  recipient: "Recipient",
  gift: "Gift",
  approval: "Approval",
  accounting: "Accounting",
  payroll: "Payroll",
};
