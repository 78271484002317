import React from "react";
import CheckboxButton from "../../CheckboxButton";
import "../_style.scss";
function CheckboxCell({
  row: { index },
  value,
  checked,
  column: { onChange },
}) {
  const onCheckboxChange = () => {
    onChange(index);
  };

  const onCheckboxCellClicked = (event) => {
    event.stopPropagation();
  };

  return (
    <div onClick={onCheckboxCellClicked}>
      <CheckboxButton
        id={`checkbox-processing-${index}`}
        name={value}
        value={value}
        checked={checked}
        onChange={onCheckboxChange}
        gtmId={
          value === "Entgelt" || value === "Fibu"
            ? "CheckboxBearbeiten"
            : undefined
        }
      />
    </div>
  );
}

export default CheckboxCell;
