import "./_style.scss";

// INFO: If you use the sidebar on a page you need to wrap the page in an additional div with className="page-with-sidebar"
function SideBarRow(props) {
  return (
    <div className="side-bar-row">
      <div className="property">{props.propertyName}</div>
      <div className="value">{props.value}</div>
    </div>
  );
}

export default SideBarRow;
