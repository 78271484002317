import { AVV_DOCUMENT_NAME } from "./Utils/constants";
// eslint-disable-next-line
export default {
  adv: {
    pdfFileName: AVV_DOCUMENT_NAME,
    trackPIPageId: "Geschenkemanager.ADV",
    projectName: "Geschenkemanager",
  },
  template: {
    employeeList: "GM-Mitarbeiter-Vorlage.xlsx",
    bussinesPartnerList: "GM-Geschaeftspartner-Vorlage.xlsx",
  },
};
