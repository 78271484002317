import APIStore from "./APIStore";
import ModalStore from "./ModalStore";
import InfoBoxStore from "./InfoBoxStore";
import UserSettingsStore from "./UserSettingsStore";
import CreateGiftStore from "./CreateGiftStore";
import SettingsStore from "./SettingsStore";
import FiltersStore from "./FiltersStore";
import StatusStore from "./StatusStore";
import HelpTextStore from "./HelpTextStore";
import EmployeeStore from "./EmployeeStore";
import GiftDataStore from "./GiftDataStore";

export default class RootStore {
  constructor() {
    this.apiStore = new APIStore(this);
    this.modalStore = new ModalStore(this);
    this.filtersStore = new FiltersStore(this);
    this.infoBoxStore = new InfoBoxStore(this);
    this.userSettingsStore = new UserSettingsStore(this);
    this.createGiftStore = new CreateGiftStore(this);
    this.settingsStore = new SettingsStore(this);
    this.statusStore = new StatusStore(this);
    this.helpTextStore = new HelpTextStore(this);
    this.employeeStore = new EmployeeStore(this);
    this.giftDataStore = new GiftDataStore(this);
  }
}
