import React from "react";
import headerLogo from "../../Assets/svg/haufe-logo-black.svg";

const logoContainer = {
  width: "229px",
  height: "66px",
  cursor: "pointer",
};

const logoDivider = {
  display: "inline-block",
  position: "absolute",
  top: "14px",
  background: "#000",
  width: "1px",
  height: "40px",
};

function HeaderLogo(props) {
  const getAdditionalLogo = () => {
    return (
      <img
        className="header-logo additional"
        src={props.additionalLogo}
        alt="Logo"
      />
    );
  };

  const getDivider = () => {
    return <div className="header-logo-divider" style={logoDivider} />;
  };

  return (
    <div className="header-logo-container" style={logoContainer}>
      <img
        className="header-logo"
        src={props.customLogo ? props.customLogo : headerLogo}
        alt="Logo Haufe"
      />
      {props.additionalLogo &&
        (props.dividerPlacementLast ? (
          <React.Fragment>
            {getAdditionalLogo()}
            {getDivider()}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getDivider()}
            {getAdditionalLogo()}
          </React.Fragment>
        ))}
    </div>
  );
}

export default HeaderLogo;
