import React from "react";

//
export const FIRST_NAME_TEXT = "Vorname";
export const LAST_NAME_TEXT = "Nachname";
export const EMAIL_TEXT = "Bitte geben Sie zunächst Ihre E-Mail-Adresse ein:";
export const PASSWORD_TEXT = "Passwort (8-30 Zeichen)";
export const PASSWORD_REPEAT_TEXT = "Passwort wiederholen";
export const BACK_TO_LOGIN_TEXT = "Zurück zum Login";
export const REGISTER_TEXT = "Registrieren";
export const YOUR_DATA_TEXT = "Ihre Daten";
export const REGISTER_DESCRIPTION_TEXT = "";
export const SEND_TEXT = "Senden";
export const FIRTS_NAME_ERROR = "Bitte geben Sie Ihren Vornamen ein.";
export const LAST_NAME_ERROR = "Bitte geben Sie Ihren Nachnamen ein.";
export const EMAIL_ERROR = "Bitte geben Sie eine gültige E-Mail-Adresse ein.";
export const PASSWORD_ERROR = "Bitte geben Sie Ihr Passwort ein.";
export const PASSWORD_NOT_STRENGTH_ERROR = `Das Passwort muss aus mindestens acht Zeichen bestehen, kein Leerzeichen, mindestens 1 Kleinbuchstabe, mindestens 1 Grossbuchstabe, mindestens 1 Zahl, mindestens 1 Sonderzeichen ( ~!@#$%^&*()_-+={}[]|:;'"<,>.? ).`;
export const PASSWORD_REPEAT_ERROR = "Bitte geben Sie Ihr Passwort erneut ein.";
export const PASSWORD_REPEAT_NOT_SAME_ERROR =
  "Ihre Eingabe stimmt nicht mit Ihrem gewählten Passwort überein.";
export const SUCCESS_MESSAGE = (action) => (
  <div>
    Registrierung erfolgreich!
    <br />
    Wir haben Ihnen eine Bestätigungsmail zugeschickt. Nachdem Sie Ihre
    E-Mail-Adresse bestätigt haben, können Sie sich
    <button className="text-button" onClick={action}>
      einloggen.
    </button>
  </div>
);

export const LOGIN_TITLE_TEXT = "Willkommen beim Geschenkemanager";
export const LOGIN_DESCRIPTION_TEXT =
  "Hier können Sie sich beim Geschenkemanager anmelden oder registrieren.";
export const LOGIN_TEXT = "Anmelden";
export const LOGIN_TEXT_BUTTON = "Login";
export const BACK_TO_EMAIL_TEXT_BUTTON = "Zurück zur E-Mail-Eingabe";
export const LOGIN_USER_DESCRIPTION =
  "Melden Sie sich hier als Administrator an:";
export const LOGIN_ADMIN_TEXT = "Zum Login für Admins";
export const LOGIN_ADMIN_DESCRIPTION =
  "Melden Sie sich hier als Administrator an:";
export const YOUR_PASSWORD_TEXT = "Bitte geben Sie Ihr Passwort ein:";
export const PASSWORD_FORGOT_TEXT = "Passwort vergessen?";
export const NEW_REGISTRATION_DESCRIPTION_TEXT =
  "Legen Sie hier ein persönliches Konto an:";
export const REGISTRATION_NOW_TEXT = "Jetzt registrieren";
export const REGISTRATION_TEXT = "Registrieren";

export const PASSWORD_FORGOT_TITLE_TEXT = "Passwort vergessen";
export const PASSWORD_FORGOT_DESCRIPTION_TEXT = "";
export const CODE_TEXT = "Code";
export const NEW_PASSWORD_TEXT = "Neues Passwort";
export const RESET_CODE_SENT_TEXT =
  "Wir haben Ihnen einen Bestätigungscode per E-Mail zugeschickt.";
export const PASSWORD_CHANGED_TEXT = "Die Passwortänderung war erfolgreich.";

export const AVV_CHECKBOX_TEXT =
  "Hiermit willige ich ein, dass meine personenbezogenen Daten, wie in der Datenschutzerklärung beschrieben, erhoben, verarbeitet und genutzt werden. Diese Einwilligung kann ich jederzeit widerrufen.";
export const NEXT_BUTTON_TEXT = "Weiter";

export const ERROR_MESSAGE_CHANGE_PW = (errCode) => {
  let msg = "";
  switch (errCode) {
    case "CodeMismatchException":
      msg = "Falscher Verifikationscode, bitte probieren Sie es noch einmal.";
      break;
    case "InvalidParameterException":
      msg =
        "Die Eingabe des Verifikationscodes ist ungültig. Bitte probieren Sie es noch einmal.";
      break;
    case "PasswordResetRequiredException":
      msg =
        'Aus technischen Gründen benötigen Sie ein neues Passwort. Bitte vergeben Sie mit "Passwort vergessen?" ein neues Passwort.';
      break;
    default:
      msg = "Das hat nicht geklappt.";
      break;
  }
  return msg;
};

export const ERROR_MESSAGE_RESET_PW = (errCode) => {
  let msg = "";
  switch (errCode) {
    case "UserNotFoundException":
      msg = "Die E-Mail-Adresse konnte nicht gefunden werden.";
      break;
    case "InvalidParameterException":
      msg =
        "Das Passwort kann nicht zurückgesetzt werden, da die E-Mail-Adresse nicht registriert oder verifiziert ist.";
      break;
    case "PasswordResetRequiredException":
      msg =
        'Aus technischen Gründen benötigen Sie ein neues Passwort. Bitte vergeben Sie mit "Passwort vergessen?" ein neues Passwort.';
      break;
    default:
      msg = "Das hat nicht geklappt.";
      break;
  }
  return msg;
};

export const ERROR_MESSAGE_LOGIN = (errCode) => {
  let msg = "";
  switch (errCode) {
    case "UserNotFoundException":
      msg = "Diese E-Mail-Adresse ist nicht registriert oder verifiziert.";
      break;
    case "NotAuthorizedException":
      msg = "Benutzername oder Passwort falsch.";
      break;
    case "UserNotConfirmedException":
      msg =
        "Ihre E-Mail-Adresse wurde noch nicht bestätigt. Bitte schließen Sie die Registrierung ab, indem Sie auf den Bestätigungslink in der E-Mail klicken. Falls Sie die E-Mail nicht mehr haben, wenden Sie sich bitte an den Support.";
      break;
    case "PasswordResetRequiredException":
      msg =
        'Aus technischen Gründen benötigen Sie ein neues Passwort. Bitte vergeben Sie mit "Passwort vergessen?" ein neues Passwort.';
      break;
    default:
      msg = "Das hat nicht geklappt.";
      break;
  }
  return msg;
};

export const ERROR_MESSAGE_REGISTER = (errCode) => {
  let msg = "";
  switch (errCode) {
    case "UsernameExistsException":
      msg = "Es existiert bereits ein Account mit dieser E-Mail-Adresse.";
      break;
    case "PasswordException":
      msg = "Die Passwörter stimmen nicht überein.";
      break;
    case "InvalidFieldsException":
      msg = "Bitte füllen Sie die leeren Eingabefelder noch aus.";
      break;
    case "DomainNotRegisterException":
      msg = (
        <div>
          Für diese E-Mail-Domain (z. B. @firma.de) wurde noch kein Unternehmen
          im Geschenkemanager angelegt.
          <br />
          Überprüfen Sie Ihre Eingabe oder wenden Sie sich an den Administrator
          für den Geschenkemanager.
        </div>
      );
      break;
    default:
      msg = "Das hat nicht geklappt.";
      break;
  }
  return msg;
};
