import { observable, action, decorate } from "mobx";

/*  Store for the StatusBar global component */

export default class StatusStore {
  title = "";
  action = undefined;
  isWarning = false;
  isStatusBarVisible = false;

  toggleStatusBar(isVisible) {
    this.isStatusBarVisible = isVisible;
  }

  reset() {
    this.title = "";
    this.action = undefined;
    this.isWarning = false;
    this.isStatusBarVisible = false;
  }
}

decorate(StatusStore, {
  // Observables
  title: observable,
  isStatusBarVisible: observable,
  action: observable,
  isWarning: observable,

  // Actions
  toggleStatusBar: action,
  reset: action,
});
