import { LINK_NAME } from "../Pages/OverviewPage/labels";

export const helpTextOverviewPage = {
  title: "",
  invitationLink: {
    id: "help-text-invitation-link",
    title: LINK_NAME,
    description: (
      <div>
        <p>
          Diesen Link können Sie per Mail an weitere Mitarbeiter versenden, die
          den Geschenkemanager einsetzen wollen.
        </p>
        <p>Zur Information können Sie folgenden Satz ergänzen:</p>
        <p>
          Über diesen Link kommen Sie zur Registrierungsseite für den
          Geschenkemanager. Nach der Registrierung können Sie selbst Geschenke
          anlegen. Nutzen Sie zur Einführung die entsprechenden Videos.
          Speichern Sie diesen Link unter Ihren Favoriten, damit Sie jederzeit
          darauf zugreifen können.
        </p>
      </div>
    ),
  },
};
