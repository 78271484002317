import ContextMenu from "../../ContextMenu";
import React from "react";

function ContextMenuCell({ column: { listItems }, row: { index } }) {
  return (
    <ContextMenu
      index={index}
      listItems={listItems}
      scrollTableRowIndex={index}
    />
  );
}

export default ContextMenuCell;
