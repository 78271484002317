import React from "react";
import { trashIcon } from "../../../Assets/dynamic-svg/general-icons";
import { tuna } from "../../../Utils/styleHelper";

function DeleteCell({ row: { index }, column: { deleteAction } }) {
  const onClick = () => {
    deleteAction(index);
  };

  return (
    <span className="icon-general" onClick={onClick}>
      {trashIcon(tuna)}
    </span>
  );
}

export default DeleteCell;
