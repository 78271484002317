import {
  BUSINESS_PARTNER_TAX_HEADER,
  COMPANY_HEADER,
  DEDUCT_TAX_HEADER,
  EMPLOYEE_TAX_HEADER,
  EMPLOYER_COMPANY_HEADER,
} from "../Pages/SettingsPage/CompanyPage/labels";

export const helpTextSettings = {
  title: COMPANY_HEADER,
  employerCompany: {
    id: "help-text-settings-employer-profile",
    title: EMPLOYER_COMPANY_HEADER,
    description: (
      <div>
        <p>Geben Sie hier Ihren Unternehmensnamen an. </p>
        <p>
          Die E-Mail Domain wird aus der E-Mail-Adresse des Admin abgeleitet.
          Sie ist wichtig für die Authentifizierung Ihrer Mitarbeiter. Damit
          haben nur Personen mit E-Mail-Adressen aus der Unternehmens-Domain
          Zugriff auf das Programm.
        </p>
      </div>
    ),
  },
  employeeTax: {
    id: "help-text-settings-employee-taxe",
    title: EMPLOYEE_TAX_HEADER,
    description: (
      <div>
        <p>
          Zuwendungen und Geschenke an Mitarbeiter sind grds.
          lohnsteuerpflichtig. Durch die Übernahme der Pauschalsteuer für
          Sachleistungen an eigene Mitarbeiter nach § 37b Abs. 2 EStG kann ein
          Unternehmen diese individuelle Lohnversteuerung vermeiden und die
          Mitarbeiter entlasten. Die Pauschalsteuer beträgt 30 % zzgl.
          Kirchensteuer und Solidaritätszuschlag.{" "}
        </p>
        <p>
          Diese Festlegung muss ein Unternehmen für ein Wirtschaftsjahr
          einheitlich treffen.{" "}
        </p>
        <p>
          Die Pauschalbesteuerung ist grundsätzlich ausgeschlossen bei
          Luxusgeschenken ({">"} 10.000 EUR/pro Geschenk) und soweit die Summe
          der Einzelgeschenke den Wert von 10.000 EUR pro Empfänger und Jahr
          übersteigt.{" "}
        </p>
        <p>
          Die Pauschalsteuer in Höhe von 30 % bemisst sich nach der Höhe der
          Aufwendungen einschließlich Umsatzsteuer (§ 37b Abs. 1 Satz 2 EStG).
        </p>
      </div>
    ),
  },
  businessPartnerTax: {
    id: "help-text-settings-business-partner-tax",
    title: BUSINESS_PARTNER_TAX_HEADER,
    description: (
      <div>
        <p>
          Zuwendungen und Geschenke an Geschäftspartner im Inland sind grds.
          einkommenssteuerpflichtig. Durch die Übernahme der Pauschalsteuer für
          Sachleistungen an Geschäftspartner im Inland nach § 37b Abs. 1 EStG
          kann ein Unternehmen deren finanzielle und organisatorische Belastung
          vermeiden. Die Pauschalsteuer beträgt 30 % zzgl. Kirchensteuer und
          Solidaritätszuschlag.
        </p>
        <p>
          Diese Festlegung muss ein Unternehmen für ein Wirtschaftsjahr
          einheitlich treffen.
        </p>
        <p>
          Die Pauschalbesteuerung ist grundsätzlich ausgeschlossen bei
          Luxusgeschenken ({">"} 10.000 EUR/pro Geschenk) und soweit die Summe
          der Einzelgeschenke den Wert von 10.000 EUR pro Empfänger und Jahr
          übersteigt.
        </p>
        <p>
          Die Pauschalsteuer in Höhe von 30 % bemisst sich nach der Höhe der
          Aufwendungen einschließlich Umsatzsteuer (§ 37b Abs. 1 Satz 2 EStG)
        </p>
      </div>
    ),
  },
  deductTax: {
    id: "help-text-settings-deduct-tax",
    title: DEDUCT_TAX_HEADER,
    description: (
      <div>
        Bei zum Vorsteuerabzug berechtigten Unternehmen ist im Hinblick auf die
        Grenze von 50 EUR (bis 2023: 35 EUR) gegenüber Geschäftspartnern auf die
        Nettoanschaffungskosten inkl. Nebenkosten ohne Vorsteuer abzustellen,
        bei nicht zum Vorsteuerabzug berechtigten Unternehmen dagegen auf die
        Bruttoanschaffungskosten inkl. Anschaffungsnebenkosten.
      </div>
    ),
  },
};
