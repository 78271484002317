import { isEmpty } from "../../../sma-shared/Utils/utils";

export const isInputFieldInValid = (
  inputFieldvalue,
  ignoreEmptyValue = false
) => {
  return isEmpty(inputFieldvalue) && ignoreEmptyValue;
};

export const validateQuestionComponentField = (questionFieldValue) => {
  return questionFieldValue === undefined || questionFieldValue === "";
};

export const isEmailDomainValid = (domain, ignoreEmptyValue = false) => {
  const domainRegex = new RegExp("^@[a-zA-Z0-9.-._]+[.][a-zA-Z]+$");
  if (isEmpty(domain) && ignoreEmptyValue) {
    return true;
  }
  if (domainRegex.test(domain)) {
    return true;
  }
  return false;
};
