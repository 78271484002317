import React from "react";
import { useTable, useSortBy } from "react-table";
import ArrowDropDownIcon from "../../Assets/svg/dropdown-arrow-down.svg";
import ArrowDropUpIcon from "../../Assets/svg/dropdown-arrow-up-black.svg";
import "./_style.scss";

function Table({
  columns,
  data,
  update,
  deleteRow,
  errors,
  bodyStyle,
  className,
  getRowProps = () => ({}),
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        update,
        deleteRow,
        errors,
        initialState: {
          sortBy: [],
        },
        autoResetSortBy: false,
        sortTypes: {
          //overwrite default alphanumeric sorting to be case non-sensitive
          alphanumeric: (row1, row2, columnName) => {
            let rowOneColumn = data[row1.index][columnName]
              ? data[row1.index][columnName]
              : "";
            let rowTwoColumn = data[row2.index][columnName]
              ? data[row2.index][columnName]
              : "";
            if (isNaN(rowOneColumn)) {
              return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
                ? 1
                : -1;
            }
            return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
          },
        },
      },
      useSortBy
    );

  return (
    <>
      <table {...getTableProps()} className={className}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      { ...column.getSortByToggleProps(), title: "" },
                      { style: column.style },
                    ])}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 24,
                      }}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {
                        <span>
                          {column.isSorted && (
                            <img
                              className="arrow-image"
                              alt=""
                              src={
                                column.isSortedDesc
                                  ? ArrowDropDownIcon
                                  : ArrowDropUpIcon
                              }
                            />
                          )}
                        </span>
                      }
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps([{ style: bodyStyle }])}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps([{ style: cell.column.style }])}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default Table;
