import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { inject, observer } from "mobx-react";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import { validateEmail, isEmpty } from "../../../sma-shared/Utils/utils";
import {
  COMPANY_TEXT,
  EMAIL_TEXT,
  FIRST_NAME_TEXT,
  LAST_NAME_TEXT,
  PERSONAL_ID,
  ERROR_ENTER_LASTNAME,
  ERROR_ENTER_FIRSTNAME,
  ERROR_ENTER_COMPANY,
  RECEIVER_PERSONALDATA_TEXT,
} from "../giftCaseLabels";
import { receiverType } from "../../../Utils/constants";
import { scrollIntoViewById } from "../../../Utils/utils";
import { helpTextReceiverEmployees } from "../../../HelpText/createGiftHelpText";

function OneReceiver(props, ref) {
  const { giftDataStore, helpTextStore } = props.rootStore;

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [company, setCompany] = useState("");
  const [personalId, setPersonalId] = useState("");
  const [email, setEmail] = useState("");

  const [isInvalidLastName, setIsInvalidLastName] = useState(false);
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [isInvalidCompany, setIsInvalidCompany] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  useEffect(() => {
    const firstReceiver = giftDataStore.receivers.length
      ? giftDataStore.receivers[0]
      : undefined;
    setLastName(firstReceiver ? firstReceiver.lastName : "");
    setFirstName(firstReceiver ? firstReceiver.firstName : "");
    setCompany(firstReceiver ? firstReceiver.company : "");
    setPersonalId(firstReceiver ? firstReceiver.personalId : "");
    setEmail(firstReceiver ? firstReceiver.email : "");
  }, [giftDataStore.receivers]);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    validate: validateOneReceiver,
  }));

  const validateOneReceiver = () => {
    const invalidatResults = [];
    const firstReceiver =
      giftDataStore.receivers.length > 0 ? giftDataStore.receivers[0] : {};

    invalidatResults.push(
      validateInput(firstReceiver.lastName, setIsInvalidLastName)
    );
    invalidatResults.push(
      validateInput(firstReceiver.firstName, setIsInvalidFirstName)
    );

    if (giftDataStore.receiverType === receiverType.bussinesPartner.value) {
      invalidatResults.push(
        validateInput(firstReceiver.company, setIsInvalidCompany)
      );
    }

    const isInvalidEmail = firstReceiver.email
      ? !validateEmail(firstReceiver.email)
      : false;
    invalidatResults.push(isInvalidEmail);
    setIsInvalidEmail(isInvalidEmail);

    return !invalidatResults.some((value) => value);
  };

  const validateInput = (value, setInvalidState, ignoreEmptyValue = false) => {
    const invalidState = ignoreEmptyValue ? false : isEmpty(value);
    setInvalidState(invalidState);
    return invalidState;
  };

  const receiversDataInputsCard = () => {
    const inputFields = [];

    inputFields.push({
      type: INPUT_CARD_TYPE.text,
      label: LAST_NAME_TEXT,
      placeholder: " ",
      value: lastName,
      isInvalid: isInvalidLastName,
      disabled: !giftDataStore.canEdit,
      errorMsg: ERROR_ENTER_LASTNAME,
      onChange: (e) => {
        handleReceiverChange("lastName", e.target.value);
        setLastName(e.target.value);
        validateInput(e.target.value, setIsInvalidLastName, true);
      },
    });
    inputFields.push({
      type: INPUT_CARD_TYPE.text,
      label: FIRST_NAME_TEXT,
      placeholder: " ",
      value: firstName,
      isInvalid: isInvalidFirstName,
      disabled: !giftDataStore.canEdit,
      errorMsg: ERROR_ENTER_FIRSTNAME,
      onChange: (e) => {
        handleReceiverChange("firstName", e.target.value);
        setFirstName(e.target.value);
        validateInput(e.target.value, setIsInvalidFirstName, true);
      },
    });

    if (giftDataStore.receiverType === receiverType.bussinesPartner.value) {
      inputFields.push({
        type: INPUT_CARD_TYPE.text,
        label: COMPANY_TEXT,
        placeholder: " ",
        value: company,
        isMandatory: true,
        isInvalid: isInvalidCompany,
        disabled: !giftDataStore.canEdit,
        errorMsg: ERROR_ENTER_COMPANY,
        onChange: (e) => {
          handleReceiverChange("company", e.target.value);
          setCompany(e.target.value);
          validateInput(e.target.value, setIsInvalidCompany, true);
        },
      });
    }

    if (giftDataStore.receiverType === receiverType.employee.value) {
      inputFields.push({
        type: INPUT_CARD_TYPE.text,
        label: PERSONAL_ID,
        placeholder: " ",
        value: personalId,
        isMandatory: false,
        disabled: !giftDataStore.canEditPersonalNumber,
        onChange: (e) => {
          handleReceiverChange("personalId", e.target.value);
          setPersonalId(e.target.value);
        },
      });
    }
    inputFields.push({
      type: INPUT_CARD_TYPE.text,
      label: EMAIL_TEXT,
      placeholder: " ",
      isMandatory: false,
      value: email,
      isInvalid: isInvalidEmail,
      disabled: !giftDataStore.canEdit,
      errorMsg: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      onChange: (e) => {
        handleReceiverChange("email", e.target.value);
        setEmail(e.target.value);
      },
      onBlur: (e) => {
        setIsInvalidEmail(
          e.target.value ? !validateEmail(e.target.value) : false
        );
      },
    });

    return inputFields;
  };

  const showHelpText = (helpTextId) => {
    helpTextStore.expandHelpText();
    scrollIntoViewById(helpTextId);
  };

  const handleReceiverChange = (field, value) => {
    if (giftDataStore.receivers.length > 0) {
      giftDataStore.receivers[0][field] = value;
    } else {
      const receiver = {};
      receiver[field] = value;
      giftDataStore.receivers.push(receiver);
    }
  };

  return (
    <InputCard
      title={RECEIVER_PERSONALDATA_TEXT}
      helpTextId={
        helpTextReceiverEmployees &&
        helpTextReceiverEmployees.receiverPersonalDataEmployees
          ? helpTextReceiverEmployees.receiverPersonalDataEmployees.id
          : ""
      }
      onClickHelpText={showHelpText}
      isMandatory
      data={receiversDataInputsCard()}
    />
  );
}

export default inject("rootStore")(observer(forwardRef(OneReceiver)));
