import XLSX from "sheetjs-style";

const createLine = (workbook, startingCell, line) => {
  XLSX.utils.sheet_add_aoa(workbook, [line], {
    origin: startingCell,
    skipHeader: true,
  });
};

const generateMerges = (workingSheet, mergesArray) => {
  if (!workingSheet["!merges"]) workingSheet["!merges"] = [];
  mergesArray.forEach((merge) => {
    workingSheet["!merges"].push(XLSX.utils.decode_range(merge));
  });
};

const styleHeader = (
  worksheet,
  startRow,
  endRow,
  headerLength,
  fontBoldness = false
) => {
  for (let index = startRow; index <= endRow; index++) {
    for (let headersIndex = 0; headersIndex < headerLength; headersIndex++) {
      const currentIndex = numToAlpha(headersIndex) + String(index);

      if (worksheet[currentIndex] !== undefined) {
        worksheet[currentIndex].s = {
          font: {
            color: { rgb: "FF000000" },
            bold: fontBoldness,
            sz: 12,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "ffc2c2c2" },
            bgColor: { rgb: "ffc2c2c2" },
          },
          border: {
            top: { style: "thin", color: { rgb: "FFd2d4d6" } },
            bottom: { style: "thin", color: { rgb: "FFd2d4d6" } },
            left: { style: "thin", color: { rgb: "FFd2d4d6" } },
            right: { style: "thin", color: { rgb: "FFd2d4d6" } },
          },
        };
      }
    }
  }
};

const numToAlpha = (num) => {
  var alpha = "";

  for (; num >= 0; num = parseInt(num / 26, 10) - 1) {
    alpha = String.fromCharCode((num % 26) + 0x41) + alpha;
  }

  return alpha;
};

export { createLine, generateMerges, styleHeader };
