import {
  ENTRY_CHECK_PAGE_TITLE,
  RECEIVER_TYPE_TEXT,
} from "../Pages/CreateGiftPages/giftCaseLabels";

export const helpTextEntryCheck = {
  title: ENTRY_CHECK_PAGE_TITLE,
  receiverType: {
    id: "help-text-receiver-type",
    title: RECEIVER_TYPE_TEXT,
    description: (
      <div>
        Für Geschenke an Mitarbeiter bzw. Geschäftspartner bestehen
        unterschiedliche gesetzliche Regelungen. Diese werden je nach Angabe in
        diesem Feld entsprechend berücksichtigt.
      </div>
    ),
  },
};
