import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./_style.scss";
import { FOOTER_FIX_HEIGHT, NAVBAR_FIX_HEIGHT } from "../../Utils/constants";

function CustomNotificationContainer(props) {
  const { createGiftStore } = props.rootStore;
  let footerHeight = FOOTER_FIX_HEIGHT;
  let navigationBarHeight = NAVBAR_FIX_HEIGHT;
  let footerVisible = true;
  let hasNavigationBar = false;
  let navigationBarVisible = false;

  useEffect(() => {
    setNotificationBottomPosition();

    window.addEventListener("scroll", setNotificationBottomPosition);
    window.addEventListener("resize", setNotificationBottomPosition);

    return () => {
      window.removeEventListener("scroll", setNotificationBottomPosition);
      window.removeEventListener("resize", setNotificationBottomPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNotificationBottomPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGiftStore.rightContainerSizeChanged]);

  useEffect(() => {
    setNotificationBottomPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGiftStore.currentWorkflowStep]);

  const calculateFooterVisible = () => {
    const footerElements = document.getElementsByClassName("footer");
    footerVisible =
      footerElements.length > 0
        ? footerElements[0].getBoundingClientRect().bottom - 35 <=
          window.innerHeight
        : false;
  };

  const calculateNavigationBarVisible = () => {
    const navigationElements =
      document.getElementsByClassName("navigation-bar");
    hasNavigationBar = navigationElements.length > 0;
    navigationBarVisible = hasNavigationBar
      ? navigationElements[0].getBoundingClientRect().bottom - 35 <=
        window.innerHeight
      : false;
  };

  const setNotificationBottomPosition = () => {
    let bottom = 0;
    calculateFooterVisible();
    calculateNavigationBarVisible();

    if (footerVisible && !hasNavigationBar) {
      bottom = footerHeight;
    } else if (!footerVisible && navigationBarVisible) {
      bottom = navigationBarHeight;
    } else if (!footerVisible && !hasNavigationBar) {
      bottom = 0;
    } else if (footerVisible && navigationBarVisible) {
      bottom = footerHeight + navigationBarHeight;
    }

    const notificationElement = document.getElementsByClassName(
      "notification-container"
    );
    if (notificationElement.length > 0) {
      notificationElement[0].style.bottom = bottom + "px";
    }
  };

  return (
    <React.Fragment>
      <NotificationContainer className="notification-container" />
    </React.Fragment>
  );
}

export default inject("rootStore")(observer(CustomNotificationContainer));
