import "./_style.scss";
import {
  arrowUpIcon,
  arrowDownIcon,
} from "../../Assets/dynamic-svg/general-icons";

function CollapsibleCard(props) {
  return (
    <div
      className={`collapsible-card ${props.isOpen ? "open" : "collapsed"}`}
      id={`collapsible-${props.title}`}
    >
      <div>
        <div className="collapsible-header">
          <div className="collapsible-title">{props.title}</div>
          <button
            type="button"
            className="btn collapsible-btn"
            onClick={props.onCollapsibleCardClicked}
          >
            {!props.isOpen ? (
              <div>{arrowDownIcon("black")}</div>
            ) : (
              <div>{arrowUpIcon("black")}</div>
            )}
          </button>
        </div>
      </div>
      <div>
        <div className="collapsible-children">
          {props.isOpen && (
            <div className="children-content">{props.children}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CollapsibleCard;
