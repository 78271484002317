import React, { useState } from "react";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import { APPROVAL_COMMENT, SELF_APPROVAL_INFO } from "../giftCaseLabels";
import "./_style.scss";

function SelfApproval(props) {
  const [comment, setComment] = useState("");

  const renderInputFields = () => [
    {
      type: INPUT_CARD_TYPE.textArea,
      label: APPROVAL_COMMENT,
      value: comment,
      id: "senderComment",
      isMandatory: false,
      placeholder: " ",
      onChange: (e) => {
        setComment(e.target.value);
      },
    },
  ];

  const onSendButtonClicked = () => {
    props.selfApproveAction(comment.replace(/(<([^>]+)>)/gi, ""));
  };

  return (
    <div className="approval-container">
      <div key="description">{SELF_APPROVAL_INFO}</div>
      <InputCard key="inputFields" data={renderInputFields()} />
      <div className="buttons-area">
        <button className="btn-ghost primary-button" onClick={props.closeModal}>
          Abbrechen
        </button>
        <button
          className={`btn btn-primary`}
          onClick={() => onSendButtonClicked()}
        >
          Senden
        </button>
      </div>
    </div>
  );
}

export default SelfApproval;
