import React from "react";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";

import "./_style.scss";
class DropdownComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getObjectArrayOfOptions = this.getObjectArrayOfOptions.bind(this);
  }

  getObjectArrayOfOptions(array) {
    const options = array.map((item) => {
      if (typeof item === "object") {
        const obj = {
          key: item.key,
          value: item.value,
          label: item.label,
        };
        return obj;
      } else if (typeof item === "string") {
        const obj = {
          key: item,
          value: item,
          label: item,
        };
        return obj;
      }
      return undefined;
    });
    return options;
  }

  onDropdownValueChange(selectedValue) {
    this.props.getSelectedDropdownValue(selectedValue.value);
    this.setState({
      value: selectedValue,
    });
  }

  getDropdownObjectFromArray(id, array) {
    if (id !== undefined && id !== "") {
      const newArray = array.map((item) => {
        const obj = { key: item.key, value: item.value, label: item.label };
        return obj;
      });
      const result = newArray.filter((item) => item.value === id);
      return result[0];
    }

    return null;
  }

  render() {
    return (
      <div className="page-input-container">
        <div>
          <Dropdown
            className={this.props.className}
            height={this.props.height}
            id={this.props.dropdownLabel}
            options={this.getObjectArrayOfOptions(this.props.dropdownOptions)}
            value={this.getDropdownObjectFromArray(
              this.props.selectedDropdownValue,
              this.props.dropdownOptions
            )}
            placeholder={this.props.placeholder}
            isSearchable={this.props.isSearchable}
            isDisabled={this.props.isDisabled}
            isInvalid={this.props.isInvalid}
            errorMsg={this.props.errorMsg}
            onChange={(selectedValue) =>
              this.onDropdownValueChange(selectedValue)
            }
            menuPlacement={this.props.menuPlacement}
          />
        </div>
      </div>
    );
  }
}

export default DropdownComponent;
DropdownComponent.defaultProps = {
  isSearchable: false,
  isDisabled: false,
  placeholder: "Bitte auswählen",
  isInvalid: false,
  noMarginBottom: false,
  isInfoButtonShown: false,
  menuPlacement: "auto",
};
DropdownComponent.propTypes = {
  isSearchable: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  dropdownOptions: PropTypes.array,
  dropdownLabel: PropTypes.string,
  info: PropTypes.string,
  isInfoButtonShown: PropTypes.bool,
  getSelectedDropdownValue: PropTypes.func,
  noMarginBottom: PropTypes.bool,
  height: PropTypes.number,
  menuPlacement: PropTypes.string,
};
