import React from "react";
import "./_style.scss";

function TextArea(props) {
  return (
    <div className="text-input-container" id={props.id}>
      <label htmlFor={props.label}>
        {props.label}{" "}
        {!props.isMandatory && <span className="optional-info">optional</span>}
      </label>
      <textarea
        name={props.label}
        className="text-area form-control"
        placeholder={props.placeholder}
        onClick={props.onClick}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
}

export default TextArea;
