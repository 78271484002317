import React, { useEffect, useState } from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import {
  getObjectByPropValueFromArray,
  isEmpty,
  validateDecimalAsText,
  roundToDecimals,
} from "../../../sma-shared/Utils/utils";
import {
  status,
  STATUS_MESSAGE_FIBU_PAYROLL,
  MAX_FILES_TO_UPLOAD,
} from "../../../Utils/constants";
import {
  showErrorInView,
  scrollIntoViewByClassName,
  scrollIntoViewById,
  getFullName,
} from "../../../Utils/utils";
import { WORKFLOW } from "../WorkflowNavigation/workflow";
import { GIFTCASE_FIBU_PATH } from "../../../Utils/paths";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import SideBar from "../SideBar";
import ReactHtmlParser from "react-html-parser";
import {
  GROSS_VALUE_TEXT,
  NET_VALUE_TEXT,
  FIBU_INVOICENUMBER,
  FIBU_RECEIPTNUMBER,
  FIBU_ADDDOCUMENT,
  COMMENT,
  ACCOUNT,
  SKR03,
  SKR04,
  PROCESSING_BY_FIBU,
  CHECK_CASE_DATA_RIGHT,
  INVOICE_INFORMATION,
  COMPLETE_TASK,
  HOW_TO_ASSIGN_ACCOUNTS,
  BOOKING_INFORMATION,
  PROCESSING_STATUS_TEXT,
  FIBU_CARD_TEXT,
  CONFIRM_INPUTS,
  ERROR_GROSS_VALUE,
  ERROR_NET_VALUE,
  ERROR_FIBU_INTERNAL_NUMBER,
} from "../giftCaseLabels";
import { helpTextGift } from "../../../HelpText/giftHelpText";
import { helpTextFibu } from "../../../HelpText/fibuPayrollHelpText";
import { actions, activeArea } from "../../../Utils/constants";

const decimalDigits = 2;

function Fibu(props) {
  const { createGiftStore, giftDataStore, helpTextStore, apiStore } =
    props.rootStore;
  const { userInfo } = apiStore;

  const [accountingStatus, setAccountingStatus] = useState();
  const [statusMessage, setStatusMessage] = useState("");
  const [comment, setComment] = useState("");
  const [fileAttachments, setFileAttachments] = useState([]);
  const [hasStatusAccountingProcessing, setHasStatusAccountingProcessing] =
    useState();

  const [grossValue, setGrossValue] = useState(giftDataStore.grossValue);
  const [netValue, setNetValue] = useState(giftDataStore.netValue);
  const [accountingGuidance, setAccountingGuidance] = useState(
    giftDataStore.guidance ? giftDataStore.guidance.accountingGuidance : ""
  );

  const [isInvalidInternalNumber, setIsInvalidInternalNumber] = useState(false);
  const [isInvalidGrossValue, setIsInvalidGrossValue] = useState(false);
  const [isInvalidNetValue, setIsInvalidNetValue] = useState(false);

  useEffect(() => {
    createGiftStore.currentWorkflowStep = getObjectByPropValueFromArray(
      WORKFLOW,
      "path",
      GIFTCASE_FIBU_PATH
    );
    createGiftStore.currentWorkflowStep.validate = validate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGiftStore]);

  useEffect(() => {
    if (giftDataStore.invoiceAttachments !== null) {
      setFileAttachments(giftDataStore.invoiceAttachments);
    }
  }, [giftDataStore.invoiceAttachments]);

  useEffect(() => {
    setGrossValue(giftDataStore.grossValue);
    setNetValue(giftDataStore.netValue);
  }, [giftDataStore.grossValue, giftDataStore.netValue]);

  useEffect(() => {
    helpTextStore.setHelpText(helpTextFibu);
    return () => {
      helpTextStore.resetHelpText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (giftDataStore.status) {
      const accountingStatus = giftDataStore.getStatus(
        status.payrollProcessing
      );
      const message = !accountingStatus
        ? STATUS_MESSAGE_FIBU_PAYROLL.unfinished
        : `${STATUS_MESSAGE_FIBU_PAYROLL.finished} am ${moment(
            accountingStatus.date
          ).format("DD.MM.YYYY")}`;

      setStatusMessage(message);
      setAccountingStatus(accountingStatus);
    }
    setHasStatusAccountingProcessing(
      giftDataStore.getStatus(status.financialAccountingProcessing)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore.status]);

  useEffect(() => {
    const message = !accountingStatus
      ? STATUS_MESSAGE_FIBU_PAYROLL.unfinished
      : `${STATUS_MESSAGE_FIBU_PAYROLL.finished} am ${moment(
          accountingStatus.date
        ).format("DD.MM.YYYY")}`;

    setStatusMessage(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingStatus]);

  useEffect(() => {
    setAccountingGuidance(
      giftDataStore.guidance ? giftDataStore.guidance.accountingGuidance : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore.guidance]);

  const acceptInvoice = () => {
    if (validate()) {
      finishAddingInvoiceWorkflow();
      giftDataStore.saveGift(() => {
        scrollIntoViewByClassName("guidance-section", {
          behavior: "smooth",
          block: "start",
        });
      });
    }
  };

  const validate = () => {
    const ivalidatResults = [];

    ivalidatResults.push(
      validateInput(giftDataStore.internalNumber, setIsInvalidInternalNumber)
    );
    ivalidatResults.push(
      validateInput(giftDataStore.grossValue, setIsInvalidGrossValue)
    );
    ivalidatResults.push(
      validateInput(giftDataStore.netValue, setIsInvalidNetValue)
    );

    const isValid = !ivalidatResults.some((value) => value);
    if (!isValid) {
      showErrorInView();
    }
    createGiftStore.currentWorkflowValidating =
      !createGiftStore.currentWorkflowValidating;
    return isValid;
  };

  const validateInput = (value, setInvalidState, ignoreEmptyValue = false) => {
    const invalidState = ignoreEmptyValue ? false : isEmpty(value);
    setInvalidState(invalidState);
    return invalidState;
  };

  const validateValue = (value) => {
    // empty string, null, undefine it is an valid imput also as a number (empty value)
    if (!value) {
      return true;
    }

    let validateNumber = validateDecimalAsText(value);
    if (validateNumber && decimalDigits > 0) {
      validateNumber &= value.indexOf(",") !== 0;
      validateNumber &=
        value.indexOf(",") === -1 ||
        value.length - value.indexOf(",") <= decimalDigits + 1; // not allow more decimal digits
      validateNumber &= value.indexOf(".") === -1; // allow only ',' as decimal digits
      validateNumber &= !(
        value.length >= 2 &&
        value[0] === "0" &&
        value[1] !== ","
      ); // if first digit is '0' next one can be only ','
      return validateNumber;
    } else {
      return false;
    }
  };

  const formatDisplayValue = (value) => {
    if (value === undefined || value === "") {
      return "";
    }
    const number = roundToDecimals(value, decimalDigits);
    return number;
  };

  const renderInvoiceSection = () => {
    const inputs = [
      {
        type: INPUT_CARD_TYPE.text,
        label: FIBU_RECEIPTNUMBER,
        isMandatory: true,
        placeholder: " ",
        value: giftDataStore.internalNumber,
        isInvalid: isInvalidInternalNumber,
        disabled: !giftDataStore.canEdit,
        errorMsg: ERROR_FIBU_INTERNAL_NUMBER,
        onChange: (e) => {
          giftDataStore.internalNumber = e.target.value;
          validateInput(e.target.value, setIsInvalidInternalNumber, true);
        },
      },
      {
        type: INPUT_CARD_TYPE.text,
        label: FIBU_INVOICENUMBER,
        placeholder: " ",
        value: giftDataStore.invoiceNumber,
        disabled: !giftDataStore.canEdit,
        onChange: (e) => {
          giftDataStore.invoiceNumber = e.target.value;
        },
        gtmInfo: {
          activeArea: activeArea.accounting,
          action: actions.insert,
          description: "InvoiceNumber",
        },
      },
      {
        type: INPUT_CARD_TYPE.text,
        label: GROSS_VALUE_TEXT,
        isMandatory: true,
        placeholder: " ",
        value: grossValue,
        isInvalid: isInvalidGrossValue,
        disabled: !giftDataStore.canEdit,
        errorMsg: ERROR_GROSS_VALUE,
        onChange: (e) => {
          numberInputOnChanged(
            e.target.value,
            setGrossValue,
            setIsInvalidGrossValue
          );
        },
        onBlur: (e) => {
          numberInputOnBlur(e.target.value, setGrossValue, "grossValue");
        },
      },
      {
        type: INPUT_CARD_TYPE.text,
        label: NET_VALUE_TEXT,
        isMandatory: true,
        placeholder: " ",
        value: netValue,
        isInvalid: isInvalidNetValue,
        disabled: !giftDataStore.canEdit,
        errorMsg: ERROR_NET_VALUE,
        onChange: (e) => {
          numberInputOnChanged(
            e.target.value,
            setNetValue,
            setIsInvalidNetValue
          );
        },
        onBlur: (e) => {
          numberInputOnBlur(e.target.value, setNetValue, "netValue");
        },
      },
      {
        step: createGiftStore.currentWorkflowStep.apiStep,
        type: INPUT_CARD_TYPE.fileUpload,
        label: FIBU_ADDDOCUMENT,
        multiple: false,
        fileAttachments: fileAttachments,
        notAllowToUpload: giftDataStore.idFromDB === "",
        rootStore: props.rootStore,
        disabled:
          !giftDataStore.canEdit ||
          fileAttachments.length >= MAX_FILES_TO_UPLOAD,
        uploadFile: async (file) =>
          apiStore.uploadInvoiceAttachment(giftDataStore.idFromDB, file),
        deleteFile: async (fileId) =>
          apiStore.deleteInvoiceAttachment(giftDataStore.idFromDB, fileId),
        downloadFile: async (fileId) =>
          apiStore.downloadInvoiceAttachment(giftDataStore.idFromDB, fileId),
      },
      {
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderAcceptInvoiceButton(),
      },
    ];
    return (
      <React.Fragment>
        <div className="page-header">
          <span className="title">{PROCESSING_BY_FIBU}</span>
          <span className="subtitle">{CHECK_CASE_DATA_RIGHT}</span>
        </div>
        <InputCard
          title={INVOICE_INFORMATION}
          helpTextId={
            helpTextGift && helpTextGift.invoiceInfo
              ? helpTextGift.invoiceInfo.id
              : ""
          }
          onClickHelpText={showHelpText}
          data={inputs}
        />
      </React.Fragment>
    );
  };

  const numberInputOnChanged = (value, setValue, setIsInvalid) => {
    const isValidValue = validateValue(value);
    isValidValue ? setValue(value) : setValue(netValue);
    validateInput(value, setIsInvalid, true);
  };

  const numberInputOnBlur = (value, setValue, fieldName) => {
    const valueFormated = formatDisplayValue(value);
    const decimalFormated =
      valueFormated === ""
        ? ""
        : valueFormated.toFixed(decimalDigits).toString().replace(/\./g, ",");
    setValue(decimalFormated);
    giftDataStore[fieldName] = decimalFormated;
  };

  const renderAccountingMessageCard = () => {
    if (hasStatusAccountingProcessing) {
      const cardElements = [];
      if (accountingStatus) {
        cardElements.push({
          type: INPUT_CARD_TYPE.label,
          label: FIBU_CARD_TEXT,
        });
      }

      cardElements.push({
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderAccountingStatus(),
      });

      if (!accountingStatus) {
        cardElements.push({
          type: INPUT_CARD_TYPE.textArea,
          label: COMMENT,
          isMandatory: false,
          placeholder: " ",
          value: comment,
          onChange: (e) => {
            setComment(e.target.value);
          },
        });
      }

      cardElements.push({
        type: INPUT_CARD_TYPE.customElement,
        customElement: renderFinishButton(),
      });

      return (
        <React.Fragment>
          <div className="line-separator" />
          <InputCard
            title={PROCESSING_STATUS_TEXT}
            isMandatory
            data={cardElements}
            helpTextId={
              helpTextFibu && helpTextFibu.statusInfo
                ? helpTextFibu.statusInfo.id
                : ""
            }
            onClickHelpText={showHelpText}
          />
        </React.Fragment>
      );
    }
  };

  const renderAccountingStatus = () => {
    return (
      <div className="status-label-area">
        <label
          className={`approval-status ${
            accountingStatus ? accountingStatus.status : ""
          }`}
        >
          {statusMessage}
        </label>
      </div>
    );
  };

  const renderAcceptInvoiceButton = () => {
    return giftDataStore.canEdit ? (
      <div className="approval-buttons-area">
        <button
          onClick={() => acceptInvoice()}
          className="btn btn-primary right"
        >
          {CONFIRM_INPUTS}
        </button>
      </div>
    ) : (
      ""
    );
  };

  const renderFinishButton = () => {
    return !accountingStatus ? (
      <div className="approval-buttons-area">
        <button
          onClick={() => finishAccountingWorkflow()}
          className="btn btn-primary"
        >
          {COMPLETE_TASK}
        </button>
      </div>
    ) : (
      ""
    );
  };

  const renderGuidance = () => {
    if (accountingGuidance) {
      return (
        <React.Fragment>
          <div className="line-separator" />
          <div className="guidance-section">
            <div className="guidance-title">{BOOKING_INFORMATION}</div>
            <div className="guidance-info">
              {ReactHtmlParser(accountingGuidance.info)}
            </div>
            <div className="guidance-title">{HOW_TO_ASSIGN_ACCOUNTS}</div>
            {Object.entries(accountingGuidance.accounts).map(
              ([key, value], index) => createAccountInfo(key, value, index)
            )}
          </div>
        </React.Fragment>
      );
    }
  };

  const createAccountInfo = (key, value, index) => {
    return (
      <div
        className={`${
          index === Object.keys(accountingGuidance.accounts).length - 1
            ? "info-container last"
            : "info-container"
        }`}
        key={uuidv4()}
      >
        <div className="title">{key}</div>
        {typeof value === "string" ? (
          <div className="info">{value}</div>
        ) : (
          <div className="info-accounts">
            <div className="info-header">
              <span>{ACCOUNT}</span>
              <span>{SKR03}</span>
              <span>{SKR04}</span>
            </div>
            <div className="line-separator" />
            <div>
              <span>{value.accountName}</span>
              <span>{value.SKR03}</span>
              <span>{value.SKR04}</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const finishAccountingWorkflow = () => {
    if (validate()) {
      let newStatus = {
        status: status.payrollProcessing,
        name: getFullName(userInfo),
        email: userInfo.Email,
        date: new Date(),
        comment: comment,
      };

      giftDataStore.lastComment = comment;

      giftDataStore.status.push(newStatus);
      giftDataStore.saveGift(
        () => {
          setAccountingStatus(newStatus);
        },
        {
          approvalStep: "financial-accounting-approval",
        }
      );
    }
  };

  const finishAddingInvoiceWorkflow = () => {
    const statusIndex = giftDataStore.status.findIndex(
      (item) => item.status === status.financialAccountingProcessing
    );
    let newStatus = {
      status: status.financialAccountingProcessing,
      name: getFullName(userInfo),
      email: userInfo.Email,
      date: new Date(),
      comment: "",
    };
    if (statusIndex >= 0) {
      giftDataStore.status.splice(statusIndex, 1, newStatus);
    } else {
      giftDataStore.status.push(newStatus);
    }
  };

  const showHelpText = (helpTextId) => {
    helpTextStore.expandHelpText();
    setTimeout(() => {
      scrollIntoViewById(helpTextId);
    }, 100);
  };

  return (
    <div className="page-with-sidebar">
      <div className="flex-container with-sidebar">
        <div className="accounting-container create-gift-page-content">
          {renderInvoiceSection()}
          {renderGuidance()}
          {renderAccountingMessageCard()}
        </div>
        <div className="grey-container-with-sidebar"></div>
      </div>
      <SideBar showSender showReceiver showGift showApproval />
    </div>
  );
}

export default inject("rootStore")(observer(Fibu));
