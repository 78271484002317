import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import { EMPLOYEE_PATH } from "../../Utils/paths";
import SAVE_ICON from "../../Assets/svg/save.svg";
import CANCEL_ICON from "../../Assets/svg/cancel.svg";
import WorkflowActions from "../CreateGiftPages/WorkflowActions";
import EditEmployee from "./EditEmployee";
import { tuna } from "../../Utils/styleHelper";
import { historyListener, showUnsavedChangesModal } from "../../Utils/utils";
import { chevronLeftIcon } from "../../Assets/dynamic-svg/general-icons";
import "./_style.scss";
import { BACK_BUTTON_TEXT } from "./labels";

function Employee(props) {
  const { employeeStore, modalStore } = props.rootStore;
  const unblock = useRef();

  useEffect(() => {
    historyListener(props.history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    unblock.current = props.history.block((location) => {
      const checkBlockingCondition = employeeStore.isEmployeeUpdated();
      if (checkBlockingCondition) {
        showUnsavedChangesModal(
          modalStore,
          location.pathname,
          allowUserToChangeRoute
        );
        return false;
      }
      return true;
    });
    return () => {
      unblock.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allowUserToChangeRoute = (targetLocation) => {
    unblock.current();
    props.history.replace(targetLocation);
  };

  const createActions = () => [
    {
      key: 1,
      name: "Speichern",
      callback: () => saveAction(),
      icon: SAVE_ICON,
      page: "Mitarbeiter",
    },
    {
      key: 2,
      name: "Abbrechen",
      callback: () => cancelAction(),
      icon: CANCEL_ICON,
      page: "Mitarbeiter",
    },
  ];

  const saveAction = () => {
    employeeStore.updateEmployee();
  };

  const cancelAction = () => {
    navigateToEmployeesList();
  };

  const navigateToEmployeesList = () => {
    if (!employeeStore.isEmployeeUpdated()) {
      allowUserToChangeRoute(EMPLOYEE_PATH);
    } else {
      showUnsavedChangesModal(
        modalStore,
        EMPLOYEE_PATH,
        allowUserToChangeRoute
      );
    }
  };

  return (
    <div className="two-container-page">
      <div className="container-left">
        <div className="sticky-container-left">
          <button
            className="back-button"
            onClick={() => {
              navigateToEmployeesList();
            }}
          >
            <span className="icon-general">{chevronLeftIcon(tuna)}</span>
            <span>{BACK_BUTTON_TEXT}</span>
          </button>
          <div className="line-separator"></div>
          <WorkflowActions actions={createActions()} />
        </div>
      </div>
      <div className="container-right">
        {" "}
        <EditEmployee />
      </div>
    </div>
  );
}
export default inject("rootStore")(observer(Employee));
