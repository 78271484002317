export const COMPANY_HEADER = "Unternehmen ";

export const EMPLOYER_COMPANY_HEADER = "Profil";
export const COMPANY_NAME_LABEL = "Firmenname";
export const EMAIL_DOMAIN_LABEL = "E-Mail Domain";
export const EMAIL_DOMAIN_PLACEHOLDER = "z.B @haufe.com";
export const NOT_MATCHING_DOMAIN_MESSAGE =
  "[ PLACEHOLDER ] Entered domain does not match the pattern z.B @haufe.com";

export const EMPLOYEE_TAX_HEADER = "Pauschalsteuer Mitarbeiter";
export const QUESTION_EMPLOYEE_TAX = `Wird die Pauschalsteuer nach § 37b Abs. 2 EStG für Mitarbeiter im laufenden Wirtschaftsjahr übernommen?
Diese Entscheidung ist einmalig pro Wirtschaftsjahr zu treffen und gilt einheitlich für alle Zuwendungen an Mitarbeiter.`;

export const BUSINESS_PARTNER_TAX_HEADER = "Pauschalsteuer Geschäftspartner";
export const QUESTION_BUSINESS_PARTNER_TAX = `Wird die Pauschalsteuer nach § 37b Abs. 1 EStG für Geschäftspartner im laufenden Wirtschaftsjahr übernommen?
Diese Entscheidung ist einmalig pro Wirtschaftsjahr zu treffen und gilt einheitlich für alle Zuwendungen an Geschäftspartner.`;

export const DEDUCT_TAX_HEADER = "Vorsteuerabzugsberechtigung";
export const QUESTION_DEDUCTION_TAX =
  "Für die Abzugsfähigkeit von Geschenken als Betriebsausgabe ist es relevant, ob Ihr Unternehmen vorsteuerabzugsberechtigt ist oder nicht.";

export const MAILBOX_HEADER = "Postfächer";
export const MAILBOX_DETAILS_TEXT = `Hinterlegen Sie hier die Postfächer der Finanzbuchhaltung und Entgeltabrechnung (zentrale Postfächer oder E-Mail-Adressen von Mitarbeitern). Diese E-Mail-Adressen werden benachrichtigt, sobald alle Informationen eines Geschenkefalls vorliegen.`;
export const FINANCIAL_ACCOUNTING_LABEL = "Finanzbuchhaltung";
export const PAYROLL_LABEL = "Entgeltabrechnung";

export const ERROR_MESSAGE_COMPANY = "Bitte geben Sie einen Firmennamen ein.";
export const ERROR_MESSAGE_DOMAIN =
  "Bitte geben Sie eine E-Mail-Domäne (z. B. @firma.de) ein.";
export const ERROR_MESSAGE_ACCOUNTING =
  "Bitte geben Sie eine gültige E-Mail-Adresse für die Finanzbuchhaltung ein.";
export const ERROR_MESSAGE_PAYROLL =
  "Bitte geben Sie eine gültige E-Mail-Adresse für die Entgeltabrechnung ein.";
