import { observable, action, decorate } from "mobx";

export default class HelpTextStore {
  isHelpTextVisible = false;
  isCollapsed = true;
  helpText = undefined;

  setHelpText(helpText) {
    this.helpText = helpText;
  }

  resetHelpText() {
    this.helpText = undefined;
    this.collapseHelpText();
  }

  expandHelpText() {
    this.isCollapsed = false;
  }

  collapseHelpText() {
    this.isCollapsed = true;
  }
}

decorate(HelpTextStore, {
  // Observables
  isHelpTextVisible: observable,
  isCollapsed: observable,
  helpText: observable,

  // Actions
  expandHelpText: action,
  collapseHelpText: action,
  setHelpText: action,
  resetHelpText: action,
});
