import "./_style.scss";
import { playIcon } from "../../Assets/dynamic-svg/general-icons";
import { royalBlue } from "../../Utils/styleHelper";
import { generateGtmEventName } from "../../Utils/utils";
import { activeArea, actions } from "../../Utils/constants";

function ProductVideo(props) {
  return (
    <div className="product-video">
      <div className="product-video-tag">Kurzvideo zur Anwendung</div>
      {props.videos &&
        props.videos.map((video, index) => (
          <button
            key={`link-${index}`}
            onClick={() => props.onClick(video)}
            data-gtmid={generateGtmEventName(
              activeArea.startpage,
              actions.open,
              "Video" + (index + 1)
            )}
          >
            <span className="icon-general">{playIcon(royalBlue)}</span>
            <span>{video.title}</span>
          </button>
        ))}
    </div>
  );
}

export default ProductVideo;
