import React from "react";
import { inject, observer } from "mobx-react";
import "./_style.scss";
import SearchFilter from "./SearchFilter";
import StatusFilter from "./StatusFilter";

function Filters(props) {
  const { filtersStore } = props.rootStore;

  return (
    <section className="filter-container">
      {
        <SearchFilter
          {...props}
          searchFor={(value) => {
            filtersStore.searchFilterValue = value;
          }}
        />
      }
      {
        <StatusFilter
          filterOptions={filtersStore.filterOptions}
          activeFilters={filtersStore.activeFilters}
          isFilterActive={filtersStore.isFilterActive}
          getNumberOfEntriesForFilter={props.getNumberOfEntriesForFilter}
        />
      }
    </section>
  );
}

export default inject("rootStore")(observer(Filters));
