import React, { useEffect, useState } from "react";
import "./_style.scss";
import moment from "moment";
import { inject, observer } from "mobx-react";
import {
  getObjectByPropValueFromArray,
  formatNumber,
} from "../../../sma-shared/Utils/utils";
import { WORKFLOW } from "../WorkflowNavigation/workflow";
import { GIFTCASE_REPORT_PATH } from "../../../Utils/paths";
import { receiverType } from "../../../Utils/constants";
import InputCard, { INPUT_CARD_TYPE } from "../../../Components/InputCard";
import SideBarRow from "../../../Components/SideBarRow";
import { listIcon } from "../../../Assets/dynamic-svg/general-icons";
import { royalBlue } from "../../../Utils/styleHelper";
import {
  status as giftStatus,
  reasonOptions,
  GIFT_VALUE_TYPE,
} from "../../../Utils/constants";
import { STATUS_LABEL } from "./reportLabels";
import ReceiversTable from "../SideBar/receiversTable";
import FileDataList from "../../../Components/FileDataList";
import {
  BERICHT_TITLE_PAGE,
  GESCHENKFALLS_TITLE_CARD,
  GIFT_TITLE_CARD,
  RECEIVER_TITLE_CARD,
  COMPANY_TEXT,
  DESCRIPTION_GIFT_TEXT,
  DESCRIPTION_OCCASION_TEXT,
  EMAIL_TEXT,
  FIRST_NAME_TEXT,
  LAST_NAME_TEXT,
  LIST_OF_RECEIVERS_TEXT,
  REASON_TEXT_SIMPLE,
  DOCUMENT_TEXT,
  INTERNAL_INVOICE_NUMBER_TEXT,
  INVOICE_NUMBER_TEXT,
  GIFTVALUE_TEXT,
  GIFTDATE_TEXT,
  PERSONAL_ID,
  NET_VALUE_TEXT,
  GROSS_VALUE_TEXT,
} from "../giftCaseLabels";

function Report(props) {
  const { createGiftStore, giftDataStore, modalStore, apiStore } =
    props.rootStore;

  const [giftStatuses, setGiftStatuses] = useState([]);

  useEffect(() => {
    createGiftStore.currentWorkflowStep = getObjectByPropValueFromArray(
      WORKFLOW,
      "path",
      GIFTCASE_REPORT_PATH
    );
    createGiftStore.currentWorkflowStep.validate = () => {
      return true;
    };
  }, [createGiftStore]);

  useEffect(() => {
    if (giftDataStore.status) {
      let allGiftSts = giftDataStore.status
        .filter(
          (data) =>
            data.status === giftStatus.draft ||
            data.status === giftStatus.approved ||
            data.status === giftStatus.rejected ||
            data.status === giftStatus.payrollProcessing ||
            data.status === giftStatus.completed
        )
        .map((data) => ({
          name: data.name,
          status: data.status,
          date: data.date,
        }));
      setGiftStatuses(allGiftSts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftDataStore.status]);

  // ----- helper methods ------
  const getApprovedByLabel = (status) => {
    if (status) {
      const text = STATUS_LABEL[status.status]
        ? STATUS_LABEL[status.status].label
        : "";
      return {
        type: INPUT_CARD_TYPE.customElement,
        customElement: (
          <div className="approvedby-label">
            {text} <strong> {status.name} </strong>
          </div>
        ),
      };
    } else {
      return "";
    }
  };

  const getGiftStatusLabel = (status) => {
    if (status) {
      const text = `${
        STATUS_LABEL[status.status] ? STATUS_LABEL[status.status].text : ""
      } am ${status.date ? moment(status.date).format("DD.MM.YYYY") : ""}`;
      return {
        type: INPUT_CARD_TYPE.customElement,
        customElement: (
          <div className="status-label-area">
            <label
              className={`approval-status ${
                status.status === giftStatus.draft
                  ? giftStatus.approved
                  : status.status
              }`}
            >
              {text}
            </label>
          </div>
        ),
      };
    } else {
      return "";
    }
  };

  const addCustomRowElement = (rowName, data) => {
    return {
      type: INPUT_CARD_TYPE.customElement,
      customElement: (
        <SideBarRow
          propertyName={rowName}
          value={data ? data : ""}
        ></SideBarRow>
      ),
    };
  };

  const showReceiverList = () => {
    modalStore.showConfirm(
      "Empfänger",
      <ReceiversTable listOfReceivers={giftDataStore.receivers} />,
      "Schließen"
    );
  };

  const getInvoiceAttachComponent = () => {
    return (
      <FileDataList
        attachments={giftDataStore.invoiceAttachments}
        idFromDB={giftDataStore.idFromDB}
        rootStore={props.rootStore}
        onDownload={async (fileId) =>
          apiStore.downloadInvoiceAttachment(giftDataStore.idFromDB, fileId)
        }
      />
    );
  };

  // ----- render methods ------
  const renderAllApprovalsMessageCard = () => {
    const cardElements = [];
    if (giftStatuses && giftStatuses.length > 0) {
      giftStatuses.map((status) => {
        cardElements.push(getApprovedByLabel(status));
        cardElements.push(getGiftStatusLabel(status));
        return status;
      });
    }
    return cardElements;
  };

  const renderReceiverDetails = () => {
    const cardElements = [];
    if (giftDataStore.receivers.length === 1) {
      cardElements.push(
        addCustomRowElement(
          LAST_NAME_TEXT,
          giftDataStore.receivers[0]
            ? giftDataStore.receivers[0].lastName
            : undefined
        )
      );
      cardElements.push(
        addCustomRowElement(
          FIRST_NAME_TEXT,
          giftDataStore.receivers[0]
            ? giftDataStore.receivers[0].firstName
            : undefined
        )
      );
      if (giftDataStore.receiverType === receiverType.bussinesPartner.value) {
        cardElements.push(
          addCustomRowElement(
            COMPANY_TEXT,
            giftDataStore.receivers[0]
              ? giftDataStore.receivers[0].company
              : undefined
          )
        );
      }

      if (giftDataStore.receiverType === receiverType.employee.value) {
        cardElements.push(
          addCustomRowElement(
            PERSONAL_ID,
            giftDataStore.receivers[0]
              ? giftDataStore.receivers[0].personalId
              : undefined
          )
        );
      }
      cardElements.push(
        addCustomRowElement(
          EMAIL_TEXT,
          giftDataStore.receivers[0]
            ? giftDataStore.receivers[0].email
            : undefined
        )
      );
    } else if (giftDataStore.receivers.length > 1) {
      cardElements.push(
        addCustomRowElement("Anzahl", giftDataStore.receivers.length)
      );
      cardElements.push({
        type: INPUT_CARD_TYPE.customElement,
        customElement: (
          <SideBarRow
            propertyName={
              <span
                className="icon-sidebar"
                id="receiver-sidebar"
                onClick={showReceiverList}
              >
                {listIcon(royalBlue)} {LIST_OF_RECEIVERS_TEXT}
              </span>
            }
            value={""}
          ></SideBarRow>
        ),
      });
    }
    cardElements.push(addCustomRowElement("", ""));
    return cardElements;
  };

  const renderGiftDetails = () => {
    const cardElements = [];
    const giftReason =
      giftDataStore.giftReason && giftDataStore.giftReason in reasonOptions
        ? reasonOptions[giftDataStore.giftReason].label
        : "";
    cardElements.push(addCustomRowElement(REASON_TEXT_SIMPLE, giftReason));
    cardElements.push(
      addCustomRowElement(
        DESCRIPTION_OCCASION_TEXT,
        giftDataStore.giftDescriptionOccasion
      )
    );
    cardElements.push(
      addCustomRowElement(DESCRIPTION_GIFT_TEXT, giftDataStore.giftDescription)
    );
    cardElements.push(
      addCustomRowElement(
        GIFTDATE_TEXT,
        giftDataStore.giftDate
          ? moment(giftDataStore.giftDate).format("DD.MM.YYYY")
          : undefined
      )
    );
    cardElements.push(
      addCustomRowElement(
        GIFTVALUE_TEXT(
          GIFT_VALUE_TYPE[giftDataStore.giftValueType]
            ? GIFT_VALUE_TYPE[giftDataStore.giftValueType].label
            : ""
        ),
        giftDataStore.giftValue
          ? formatNumber(giftDataStore.giftValue) + " EUR"
          : undefined
      )
    );
    cardElements.push(
      addCustomRowElement(
        GROSS_VALUE_TEXT,
        giftDataStore.grossValue
          ? formatNumber(giftDataStore.grossValue) + " EUR"
          : undefined
      )
    );
    cardElements.push(
      addCustomRowElement(
        NET_VALUE_TEXT,
        giftDataStore.netValue
          ? formatNumber(giftDataStore.netValue) + " EUR"
          : undefined
      )
    );
    cardElements.push(
      addCustomRowElement(INVOICE_NUMBER_TEXT, giftDataStore.invoiceNumber)
    );
    cardElements.push(
      addCustomRowElement(
        INTERNAL_INVOICE_NUMBER_TEXT,
        giftDataStore.internalNumber
      )
    );
    cardElements.push(
      addCustomRowElement(DOCUMENT_TEXT, getInvoiceAttachComponent())
    );
    cardElements.push(addCustomRowElement("", ""));
    return cardElements;
  };

  return (
    <div className="bericht-container flex-container">
      <div className="create-gift-page-content">
        <div className="page-header">
          <span className="title">{BERICHT_TITLE_PAGE}</span>
        </div>
        <InputCard
          title={GESCHENKFALLS_TITLE_CARD}
          data={renderAllApprovalsMessageCard()}
        />
        <div className="falldaten-details">
          <InputCard
            title={RECEIVER_TITLE_CARD}
            data={renderReceiverDetails()}
          />
          <InputCard title={GIFT_TITLE_CARD} data={renderGiftDetails()} />
        </div>
      </div>
      <div className="grey-container-without-sidebar"></div>
    </div>
  );
}

// ------------------

export default inject("rootStore")(observer(Report));
