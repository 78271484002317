import React from "react";
import FileDataCard from "../FileData";
import "./_style.scss";

function FileAttachments(props) {
  const { fileAttachments } = props;

  return fileAttachments !== undefined && fileAttachments.length !== 0 ? (
    <div className="files">
      {fileAttachments.map((file, index) => {
        const { fileName, fileSize, fileId } = file;
        return (
          <div key={`uploaded-files-${index}`} className="file-row">
            <FileDataCard
              fileName={fileName}
              fileId={fileId}
              fileSize={fileSize}
              rootStore={props.rootStore}
              onDelete={props.onDelete}
              onDownload={props.onDownload}
            />
          </div>
        );
      })}
    </div>
  ) : (
    ""
  );
}

export default FileAttachments;
