/**
 * Higher Order Component for the Overview Page.
 * holds the state for the Filters and Table.
 */

import React, { useEffect } from "react";
import RightContent from "./RightContent";
import LeftContent from "./LeftContent";
import { inject, observer } from "mobx-react";
import { historyListener } from "../../Utils/utils";

function OverviewPage(props) {
  const { filtersStore } = props.rootStore;

  useEffect(() => {
    historyListener(props.history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="two-container-page">
      <LeftContent history={props.history} />
      <RightContent
        history={props.history}
        activeFilters={filtersStore.activeFilters}
        searchFilterValue={filtersStore.searchFilterValue}
      />
    </div>
  );
}

export default inject("rootStore")(observer(OverviewPage));
