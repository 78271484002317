const VideoPlayer = (props) => (
  <div>
    <iframe
      title="video player"
      allowFullScreen={true}
      width="620"
      height="348"
      src={props.source}
    ></iframe>
  </div>
);

export default VideoPlayer;
