import React, { useState, useEffect } from "react";
import "./_style.scss";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { WORKFLOW } from "./workflow";
import { getIndexOfObjectByPropValueFromArray } from "../../../sma-shared/Utils/utils";
import { ReactComponent as Checkmark } from "../../../Assets/svg/checkmark-icon.svg";
import { summaryIcon } from "../../../Assets/dynamic-svg/general-icons";
import { blackPearl } from "../../../Utils/styleHelper";

function WorkflowNavigation(props) {
  const { apiStore, createGiftStore, giftDataStore } = props.rootStore;
  const [workflow, setWorkflow] = useState(WORKFLOW);

  useEffect(() => {
    setWorkflowStepsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWorkflowStepsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createGiftStore.currentWorkflowStep,
    apiStore.roles,
    giftDataStore.status,
  ]);

  useEffect(() => {
    setWorkflowStepsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGiftStore.currentWorkflowValidating]);

  const setWorkflowStepsState = () => {
    const currentStep = createGiftStore.currentWorkflowStep;
    const currentIndex = getIndexOfObjectByPropValueFromArray(
      WORKFLOW,
      "path",
      currentStep.path
    );
    const newWorkflow = [...WORKFLOW];
    const firstInvalidWorkflow = newWorkflow.findIndex(
      (w) => !w.isValid(giftDataStore)
    );

    for (let i = 0; i < newWorkflow.length; i++) {
      if (i === currentIndex) {
        newWorkflow[i].state = "current";
      } else if (
        (firstInvalidWorkflow < 0 || i < firstInvalidWorkflow) &&
        i < newWorkflow.length - 1
      ) {
        newWorkflow[i].state = "done";
      } else if (firstInvalidWorkflow > 0 && i > firstInvalidWorkflow) {
        newWorkflow[i].state = "notAllow";
      } else {
        newWorkflow[i].state = "";
      }
    }

    setWorkflow(newWorkflow);
  };

  const onWorkflowStepClicked = (event, stepId) => {
    const stepIsValid = createGiftStore.currentWorkflowStep.validate();
    const isUserGoingForward = stepId > createGiftStore.currentWorkflowStep.id;
    if (isUserGoingForward) {
      if (!stepIsValid) {
        event.preventDefault();
      } else {
        if (giftDataStore.isGiftDataChanged()) {
          giftDataStore.saveGift();
        }
      }
    } else {
      if (giftDataStore.isGiftDataChanged()) {
        giftDataStore.saveGift();
      }
    }
  };

  return (
    <div className={"workflow-component"}>
      {workflow.map(
        (step, index) =>
          step.isVisible(props.rootStore) && (
            <NavLink
              to={step.path(props.match.params.giftId)}
              onClick={(event) => onWorkflowStepClicked(event, step.id)}
              activeClassName="active"
              className={`StepProgress-item__title ${step.state}`}
              id={step.css_stepId}
              key={step.title + "_" + index}
            >
              {step.icon ||
              (step.state !== "done" && step.state !== "previous") ? (
                <img className="nav-icon" src={step.icon} alt="" />
              ) : (
                <Checkmark />
              )}
              <div className="circle" />
              {workflow.length - 1 === index && (
                <React.Fragment>{summaryIcon(blackPearl)}</React.Fragment>
              )}
              {step.title}
            </NavLink>
          )
      )}
    </div>
  );
}

WorkflowNavigation.propTypes = {
  rootStore: PropTypes.object.isRequired,
};

export default withRouter(inject("rootStore")(observer(WorkflowNavigation)));
